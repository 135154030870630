import React, { useState } from "react";
import {
  Grid,
  useMediaQuery,
  useTheme as T,
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import RightArrowIcon from "../assets/icons/RightArrowIcon";
import RightArrowSelected from "../assets/icons/RightArrowSelected";
import {
  UserRoundCog,
  RectangleEllipsis,
  Settings,
  ChevronDown,
} from "lucide-react";
import { useTheme } from "../assets/themeContext";
import NavBar from "../components/HeaderBar";

const FAQ: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const [activeCategory, setActiveCategory] = useState<
    "profile-setup" | "verification" | "settings"
  >("profile-setup");
  const isLarge = useMediaQuery(t.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(t.breakpoints.down("sm"));

  // FAQ Data
  const faqData = {
    "profile-setup": [
      {
        question: "How do I create a profile to send money to India?",
        answer:
          "To create a profile, sign up on our platform, provide your personal details, and verify your identity to start sending money to India.",
      },
      {
        question: "What information do I need to set up my profile?",
        answer:
          "You’ll need to provide your full name, email address, phone number, and a valid Canadian government-issued ID for verification.",
      },
      {
        question: "Can I link multiple bank accounts to my profile?",
        answer:
          "Yes, you can link multiple Canadian bank accounts to your profile for seamless money transfers to India.",
      },
      {
        question: "How do I add a recipient in India to my profile?",
        answer:
          "Go to the 'Recipients' section in your profile, enter the recipient’s details (name, bank account, and IFSC code), and save the information.",
      },
      {
        question: "Is my profile information secure?",
        answer:
          "Yes, we use advanced encryption and security measures to protect your personal and financial information.",
      },
    ],
    verification: [
      {
        question: "What documents are accepted for verification?",
        answer:
          "We accept Canadian passports, driver’s licenses, or permanent resident cards for identity verification.",
      },
      {
        question: "How long does the verification process take?",
        answer:
          "Verification is typically completed within 1-2 business days after you submit your documents.",
      },
      {
        question: "What happens if my verification fails?",
        answer:
          "If your verification fails, we’ll notify you with the reason and guide you on how to resubmit the correct documents.",
      },
      {
        question: "Can I send money to India before my account is verified?",
        answer:
          "No, you must complete the verification process to ensure compliance and security before sending money.",
      },
      {
        question: "Why do I need to verify my identity to send money to India?",
        answer:
          "Identity verification is required to comply with Canadian regulations and ensure secure transactions for both senders and recipients.",
      },
    ],
    settings: [
      {
        question: "How do I update my password?",
        answer:
          "You can update your password in the security settings section.",
      },
      {
        question: "How do I delete my account?",
        answer: "You can delete your account from the account settings page.",
      },
      {
        question: "What should I do if I forget my password?",
        answer:
          "Click on 'Forgot Password' on the login page, enter your email, and follow the instructions to reset your password.",
      },
    ],
  };

  return (
    <Grid
      lg={12}
      md={12}
      xs={12}
      xl={12}
      sm={12}
      sx={{ backgroundColor: theme.palette.primary.dark, minHeight: "100vh" }}
    >
      <NavBar highlightDashboard={true} />
      <Grid
        lg={12}
        md={12}
        sm={12}
        xs={12}
        xl={12}
        height={"80%"}
        padding={isLarge ? t.spacing(2) : t.spacing(3)}
      >
        <label
          style={{
            color: theme.palette.primary.main,
            fontSize: "25px",
            fontWeight: 700,
            lineHeight: "42px",
          }}
        >
          FAQ'S
        </label>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "flex-start",
            marginTop: "20px",
            height: "100%",
          }}
        >
          <Grid container>
            {/* Left Side: FAQ Categories */}
            <Grid
              item
              lg={3}
              md={4}
              sm={12}
              xs={12}
              sx={{ padding: "20px", width: { xs: "100%", md: "30%" } }}
            >
              <List sx={{ padding: "0" }}>
                {[
                  {
                    id: "profile-setup",
                    label: "Profile Setup",
                    icon: <UserRoundCog />,
                  },
                  {
                    id: "verification",
                    label: "Verification",
                    icon: <RectangleEllipsis />,
                  },
                  { id: "settings", label: "Settings", icon: <Settings /> },
                ].map((category) => (
                  <ListItemButton
                    key={category.id}
                    selected={activeCategory === category.id}
                    onClick={() =>
                      setActiveCategory(category.id as typeof activeCategory)
                    }
                    style={{
                      backgroundColor: theme.palette.info.light,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "0.5px solid #FFFFFF",
                    }}
                    sx={{
                      padding: "15px",
                      boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.15)",
                      borderRadius: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 50,
                          height: 50,
                          bgcolor:
                            activeCategory === category.id
                              ? theme.palette.primary.main
                              : "rgba(109, 30, 212, 0.3)",
                        }}
                      >
                        {category.icon}
                      </Avatar>
                      <ListItemText
                        primary={category.label}
                        primaryTypographyProps={{
                          fontFamily: "Inter",
                          fontSize: 18,
                          fontWeight: "medium",
                          letterSpacing: 0,
                          color: theme.palette.text.primary,
                        }}
                      />
                    </Box>
                    <ListItemIcon
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {activeCategory === category.id ? (
                        <RightArrowSelected />
                      ) : (
                        <RightArrowIcon />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                ))}
              </List>
            </Grid>

            {/* Right Side: FAQ Accordion */}
            <Grid
              item
              lg={9}
              md={8}
              sm={12}
              xs={12}
              sx={{
                padding: isSmallScreen ? "20px" : "40px",
                margin: "0 auto",
              }}
            >
              {faqData[activeCategory].map((faq, index) => (
                <Accordion
                  key={index}
                  sx={{ width: "90%", marginBottom: "15px", borderRadius: "10px" }}
                >
                  <AccordionSummary
                    expandIcon={<ChevronDown style={{ color: theme.palette.primary.main }}/>}
                    sx={{
                      padding: "5px 10px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      backgroundColor:theme.palette.info.light,
                      color:theme.palette.text.primary
                      // borderBottom: `0.5px solid ${theme.palette.primary.main}`
                    }}
                  >
                    <Typography>{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "15px", fontSize: "14px" , backgroundColor: theme.palette.primary.dark,color:theme.palette.text.primary}}>
                    <Typography>{faq.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FAQ;
