import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, Button } from "@mui/material";
import { OtpInput } from "reactjs-otp-input"; // Ensure correct import, might need default or named based on export
import { useTheme } from "../assets/themeContext";
import { sendOtp, verifyOtp } from "../services/Authapis";
import { toast, ToastContainer } from "react-toastify";

interface VerifyMobileProps {
  setOpenPhoneDialog: React.Dispatch<React.SetStateAction<boolean>>;
  updateUserData?: any;
  userId?: string;
}

const VerifyMobile: React.FC<VerifyMobileProps> = ({
  setOpenPhoneDialog,
  updateUserData,
  userId,
}) => {
  const { theme } = useTheme();
  const [otp, setOtp] = useState<string>("");

  const hasFetchedOtp = useRef<boolean>(false); // ✅ Prevent multiple calls

  const handleChange = (otp: string) => setOtp(otp);

  const SendToMobile = async (userId: string) => {
    try {
      const response = await sendOtp(userId);
      if(response?.message) {
        toast.success(response.message,{autoClose: 2000});
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userId && !hasFetchedOtp.current) {
      hasFetchedOtp.current = true; 
      SendToMobile(userId);
    }
  }, [userId]); 

  const verifyMobileOtp = async () => {
    try {
      const response = await verifyOtp(userId, otp);
      if(response?.message) {
        toast.success(response.message,{autoClose: 2000});
        setTimeout(() => {
          updateUserData();
        }, 2000);
      }
      setOpenPhoneDialog(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid padding={"20px"}>
      <Grid>
        <label style={{ fontSize: "20px", color: theme.palette.text.primary }}>
          One-Time Password
        </label>
      </Grid>
      <Box marginTop="15px">
        <OtpInput
          value={otp}
          onChange={handleChange}
          isInputNum={true} 
          numInputs={6}
          separator={<span></span>}
          inputStyle={{
            width: "35px",
            height: "35px",
            marginTop: "10px",
            marginBottom: "20px",
            marginRight: "20px",
            fontFamily: "Inter",
            fontSize: "15px",
            borderRadius: "6px",
            border: `0.5px solid ${theme.palette.text.primary}`,
          }}
        />
      </Box>
      <Grid sx={{ display: "flex", flexDirection: "column" }}>
        <label
          style={{
            fontSize: "15px",
            color: theme.palette.text.primary,
            fontFamily: "Inter",
          }}
        >
          Enter the OTP sent to your Mobile Number
        </label>
        <label
          style={{
            fontSize: "15px",
            color: theme.palette.primary.main,
            fontFamily: "Inter",
            fontWeight: 600,
            paddingTop: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            hasFetchedOtp.current = false; 
            SendToMobile(userId!);
          }}
        >
          Resend
        </label>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#1F1F1F",
            lineHeight: "24px",
            textAlign: "center",
            borderRadius: "16px",
            paddingX: "35px",
            paddingY: "10px",
            textTransform: "initial",
            bgcolor: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
          }}
          onClick={() => setOpenPhoneDialog(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={otp.length !== 6}
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#FFFFFF",
            lineHeight: "24px",
            textAlign: "center",
            borderRadius: "16px",
            paddingX: "35px",
            paddingY: "10px",
            textTransform: "initial",
            bgcolor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onClick={verifyMobileOtp}
        >
          Verify
        </Button>
      </Grid>
    </Grid>
  );
};
export default VerifyMobile;
