import React, { useEffect, useState } from "react";
import {
  Grid,
  Chip,
  Box,
  Pagination,
  useMediaQuery,
  useTheme as T,
  Button,
} from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/reducers";
import { selectCard, deslectCard } from "../redux/slices/chooseRecSlice";
import { format } from "date-fns";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProgressLoader from "./Loader";
import { getTotalTransactions } from "../services/Transactionapis";
import TransactionModal from "./TransactionModal";
import { useNavigate } from "react-router-dom";

interface MappedTransaction {
  rowId: any;
  name: string;
  usdAmount: number;
  inrAmount: number;
  recipientId: string;
  date: string;
  status: string;
  inrTransactionNumber: string | null;
  usdTransactionNumber: string | null;
  method: string;
  notes: string;
}

interface Transaction {
  _id: string;
  recipients_id: string;
  usd_transfer_amount: number;
  inr_transfer_amount: number;
  transaction_initiation_at: string;
  is_completed: boolean;
  is_error: boolean;
  is_pending: boolean;
  is_refunded: boolean;
  notes: string;
  transaction_completed_time: Date | null;
  payment_method_id: string;
  refund_initiated_at: Date | null;
  refund_completed_at: Date | null;
  inr_transaction_number: string | null;
  usd_transaction_number: string | null;
  refund_transaction_number: string | null;
  recipient_details: {
    first_name: string;
    last_name: string;
  };
  date: string;
}

export function SortedDescendingIcon() {
  const { theme } = useTheme();
  return <ExpandMoreIcon sx={{ color: theme.palette.info.main }} />;
}

export function SortedAscendingIcon() {
  const { theme } = useTheme();
  return <ExpandLessIcon sx={{ color: theme.palette.info.main }} />;
}

const TransactionTable: React.FC = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = T();
  const [trasactionData, setTransactionData] = useState<MappedTransaction[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = React.useState(1);
  const [openTransaction, setOpenTransaction] = useState<boolean>(false);
  const [
    selectedTransaction,
    setSelectedTransaction,
  ] = useState<MappedTransaction | null>(null);

  const { recipientName, paymentMethodId, recipientId, inrAmount, usdAmount } = useSelector(
    (state: RootState) => state.chooseRecipient
  );

 
  function getStatus(transaction: Transaction): string {
    if (transaction.is_pending) {
      return "Pending";
    } else if (transaction.is_refunded) {
      return "Refunded";
    } else if (transaction.is_error) {
      return "Error";
    } else if (transaction.is_completed) {
      return "Completed";
    } else {
      return "Unknown";
    }
  }

  const fetchTransactions = async (page: number) => {
    setLoading(true);
    try {
      const result = await getTotalTransactions(page);
      setCount(result.count);
      const mappedData: MappedTransaction[] = result.data.map(
        (transaction: Transaction, index: number) => ({
          rowId: transaction._id,
          name: `${transaction.recipient_details.first_name} ${transaction.recipient_details.last_name}`,
          usdAmount: transaction.usd_transfer_amount,
          inrAmount: transaction.inr_transfer_amount,
          recipientId : transaction?.recipients_id,
          date: format(
            new Date(transaction.transaction_initiation_at),
            "dd/MM/yyyy"
          ),
          status: getStatus(transaction),
          inrTransactionNumber: transaction.inr_transaction_number,
          usdTransactionNumber: transaction.usd_transaction_number,
          method: transaction.payment_method_id,
          notes: transaction.notes,
        })
      );

      setTransactionData(mappedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(page);
  }, [page]);

  const isXs = useMediaQuery(t.breakpoints.only("xs"));
  const isSm = useMediaQuery(t.breakpoints.only("sm"));
  const isMd = useMediaQuery(t.breakpoints.only("md"));
  const isLg = useMediaQuery(t.breakpoints.only("lg"));
  const isXl = useMediaQuery(t.breakpoints.only("xl"));

  const getColumnWidth = (column: string) => {
    switch (column) {
      case "name":
        if (isXs) return 200;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return undefined;
        if (isXl) return undefined;
        return undefined;

      case "usdAmount":
        if (isXs) return 200;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return undefined;
        if (isXl) return undefined;
        return undefined;

      case "inrAmount":
        if (isXs) return 200;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return undefined;
        if (isXl) return undefined;
        return undefined;

      case "date":
        if (isXs) return 150;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return undefined;
        if (isXl) return undefined;
        return undefined;

      case "status":
        if (isXs) return 150;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return undefined;
        if (isXl) return undefined;
        return undefined;
      default:
        return undefined;
    }
  };

  // get flex based on breakpoints and columns
  const getColumnFlex = (column: string) => {
    switch (column) {
      case "name":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return 1;
        if (isXl) return 1;
        return undefined;

      case "usdAmount":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return 1;
        if (isXl) return 1;
        return undefined;

      case "inrAmount":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return 1;
        if (isXl) return 1;
        return undefined;

      case "date":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return 1;
        if (isXl) return 1;
        return undefined;

      case "status":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return 1;
        if (isXl) return 1;
        return undefined;

        case "action":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return 1;
        if (isXl) return 1;
        return undefined;

      default:
        return undefined;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: getColumnWidth("name"),
      flex: getColumnFlex("name"),
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary }}>
          {params.value}
        </label>
      ),
    },
    {
      field: "usdAmount",
      headerName: "USD Amount",
      width: getColumnWidth("usdAmount"),
      flex: getColumnFlex("usdAmount"),
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary }}>
          {`$${params.value}`}
        </label>
      ),
    },
    {
      field: "inrAmount",
      headerName: "INR Amount",
      width: getColumnWidth("inrAmount"),
      flex: getColumnFlex("inrAmount"),
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary, outline: "none" }}>
          {`₹${params.value}`}
        </label>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: getColumnWidth("date"),
      flex: getColumnFlex("date"),
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary, outline: "none" }}>
          {params.value}
        </label>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: getColumnWidth("status"),
      flex: getColumnFlex("status"),
      renderCell: (params: GridRenderCellParams) => {
        let statusColor: string;

        switch (params.value.toLowerCase()) {
          case "pending":
            statusColor = "#FFA500";
            break;
          case "refunded":
            statusColor = "blue";
            break;
          case "error":
            statusColor = "red";
            break;
          case "completed":
            statusColor = "#4CBB17";
            break;
          default:
            statusColor = "inherit";
        }

        return (
          <Chip
            label={params.value}
            sx={{
              color: statusColor,
              backgroundColor: "transparent",
              fontSize: "14px",
              cursor: "default",
              fontWeight: 520,
              border: "none",
            }}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: getColumnWidth("action"),
      flex: getColumnFlex("action"),
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.status === "Completed") {
          return (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="no-modal"
            >
              <Button
                variant="contained"
                className="no-modal"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  lineHeight: "24px",
                  textAlign: "center",
                  borderRadius: "16px",
                  paddingX: "15px",
                  paddingY: "2px",
                  marginY: "10px",
                  textTransform: "initial",
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleSecureTransfer(params.row as MappedTransaction)
                }}
              >
                Transfer Again
              </Button>
            </Grid>
          );
        }
      },
    },
  ];

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };


  const handleSecureTransfer = (row : MappedTransaction) => {
          navigate("/transactions");
          const userName =row?.name;
          const paymentMethod = row?.method;
          const recipientID = row?.recipientId;
          const inrValue = row?.inrAmount.toString();
          const usdValue = row?.usdAmount.toString();
  
          if (
            userName === recipientName &&
            paymentMethod === paymentMethodId &&
            recipientID === recipientId && 
            inrValue === inrAmount &&
            usdValue === usdAmount
          ) {
            dispatch(deslectCard());
          } else {
            if (userName && paymentMethod && recipientID && inrValue && usdValue) {
              dispatch(
                selectCard({
                  inrAmount: inrValue.toString(),
                  usdAmount: usdValue.toString(),
                  recipientName: userName,
                  paymentMethodId: paymentMethod,
                  recipientId: recipientID,
                })
              );
            }
          }
        };


  return (
    <Grid
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
      }}
      lg={12}
      md={12}
      xs={12}
      sm={12}
      xl={12}
    >
      <Grid>
        <label
          style={{
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "32px",
            color: theme.palette.text.primary,
          }}
        >
          Transaction History
        </label>
      </Grid>
      <Grid marginTop={"15px"}>
        {loading ? (
          <Grid
            width={"100%"}
            height={"100%"}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressLoader strokeColor="#6D1ED4" fillColor="#6D1ED4" />
          </Grid>
        ) : (
          <DataGrid
            rows={trasactionData}
            columns={columns}
            autoHeight
            getRowId={(row) => row?.rowId}
            autoPageSize
            disableColumnMenu 
            disableColumnResize
            hideFooterPagination={true}
            disableColumnSelector={true}
            hideFooter={true}
            disableRowSelectionOnClick 
            onRowClick={(params, event) => {
              if ((event.target as HTMLElement).closest(".no-modal")) {
                return; // Prevent opening modal if clicked inside elements with class 'no-modal'
              }
              setSelectedTransaction(params.row as MappedTransaction);
              setOpenTransaction(true);
            }}
            slots={{
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
            }}
            sx={{
              cursor: "pointer",
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
                cursor: "pointer",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
                cursor: "pointer",
              },
              "& .MuiDataGrid-row": {
                border: "none",
                flex: "1",
                cursor: "pointer",
              },
              "& .MuiDataGrid-cell": {
                border: "none",
                bgcolor: theme.palette.info.light,
                flex: "1",
                cursor: "pointer",
              },
              "& .MuiDataGrid-columnHeader": {
                border: "none",
                bgcolor: theme.palette.info.light,
                fontFamily: "Inter",
                color: theme.palette.info.main,
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "24px",
                flex: "1",
              },
              "& .MuiDataGrid-iconButtonContainer": {
                visibility: "visible",
                outline: "none",
              },
              "& .MuiDataGrid-sortIcon": {
                opacity: "inherit !important",
                color: theme.palette.info.main,
                outline: "none",
              },
              "&, [class^=MuiDataGrid]": { border: "none" },
              border: "none",
              fontFamily: "Inter",
              color: theme.palette.info.main,
              fontWeight: 500,
              bgcolor: theme.palette.info.light,
              fontSize: "15px",
              lineHeight: "24px",
              flex: "1",
            }}
          />
        )}
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={page}
            onChange={handleChange}
            sx={{
              "& .MuiPaginationItem-root": {
                color: theme.palette.text.primary,
                "&.Mui-selected": {
                  bgcolor: theme.palette.primary.main,
                  color: "#FFFFFF",
                },
              },
            }}
          />
        </Box>
      </Grid>
      <TransactionModal
        show={openTransaction}
        onHide={() => setOpenTransaction(false)}
        transactionDetails={selectedTransaction}
      />
    </Grid>
  );
};
export default TransactionTable;
