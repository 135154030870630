import React, { useCallback, useEffect } from "react";
import NavBar from "../components/HeaderBar"; // Import your NavBar component
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { getKycToken, getUserDetails, updateKycStatus } from "../services/Profileapis";
// Define an interface for the Hyperverge KYC result

const KYC: React.FC = () => {
  const { theme } = useTheme();
  useEffect(() => {
    document.title = "AptRemit-KYC Verification";
  });

  const getUserProfileDetails = async () => {
      try {
        const data = await getUserDetails();
        let kyc;
        if (data?.kyc_verified === null) {
          kyc = false;
        } else {
          kyc = data?.kyc_verified;
        }
        sessionStorage.setItem("kyc_verified", kyc.toString());
      } catch (err) {
        console.error(err);
      }
    };

  const startOnboarding = useCallback(async () => {
    const response = await getKycToken();
    // Check if the SDK classes are available on the window object
    if (window.HyperKycConfig && window.HyperKYCModule) {
      // Create a new configuration object for the KYC process
      const hyperKycConfig = new window.HyperKycConfig(
        response?.access_token,
        response?.workflow_id,
        response?.transaction_id
      );
      const handler = async (HyperKycResult: any) => {
        window.parent.postMessage({ status: "USER_CLOSE" }, "*");
        try {
          await updateKycStatus({ is_kyc_submitted: true });
          await getUserProfileDetails();
        } catch (error) {
          console.error("Failed to update KYC status:", error);
        }
      };
      window.HyperKYCModule.launch(hyperKycConfig, handler);
    } else {
      console.error("Hyperverge KYC SDK not loaded");
    }
  }, []);

  return (
    <Grid
      xl={12}
      lg={12}
      md={12}
      sx={{ backgroundColor: theme.palette.primary.dark, height: "100vh" }}
    >
      <NavBar highlightDashboard={true} />
      <Grid
        sx={{
          padding: "15px",
          borderRadius: "20px",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        xl={12}
      >
        <Grid
          xs={12}
          sm={10}
          md={8}
          lg={6}
          xl={7}
          sx={{
            bgcolor: theme.palette.info.light,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "16px",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              color: theme.palette.text.primary,
              fontWeight: 500,
              textAlign: "center",
              px: 2,
            }}
          >
            Start your KYC process for a seamless and secure
            <br /> cross-border transaction experience.
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "10px",
              bgcolor: theme.palette.primary.main,
              paddingY: "15.5px",
              paddingX: "25px",
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              gap: "15px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "19.36px",
              color: "#FFFFFF",
              marginTop: "20px",
              textTransform: "initial",
              "&:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            onClick={startOnboarding}
          >
            Start KYC
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KYC;
