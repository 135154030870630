import React,{useEffect} from "react";
import NavBar from "../components/HeaderBar"; // Import your NavBar component
import { Grid, useTheme as T, useMediaQuery} from "@mui/material";
import { useTheme } from "../assets/themeContext";
import LivePriceCard from "../components/LivePriceCard";
import ReferNowCard from "../components/ReferNowCard";
import TransactionHistory from "../components/TransactionHistory";
import TopRefferels from "../components/TopRefferels";
import AnalyticsCard from "../components/AnalyticsCard";
import Conversion from "./Conversion";


const DashboardPage: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const isLarge = useMediaQuery(t.breakpoints.down('md'))
  const HideReferCard = useMediaQuery(t.breakpoints.down('md'))
  useEffect(()=>{
    document.title='AptRemit-Dashboard'
  })


  return (
    <Grid
      lg={12}
      sm={12}
      xs={12}
      xl={12}
      md={12}
      sx={{ backgroundColor: theme.palette.primary.dark, height:'100vh', display:'flex',flexDirection:'column',justifyContent:'space-around'}}
    >
      <NavBar highlightDashboard={true} /> {/* Render NavBar component */}
      <Grid
        sx={{ backgroundColor: theme.palette.primary.dark }}
        paddingX={isLarge?t.spacing(2):t.spacing(3)}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        lg={12}
      sm={12}
      xs={12}
      xl={12}
      md={12}

      >
        <label
          style={{
            color: theme.palette.primary.main,
            fontSize: "34px",
            fontWeight: 700,
            lineHeight: "42px",
          }}
        >
          Dashboard
        </label>
        {/* Add your dashboard content here */}

       <Grid
          paddingY={"20px"}
          lg={12}
          sm={12}
          xs={12}
          md={12}
          xl={12}
          container
          height={'100%'}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap:'20px',
            flexWrap:'wrap'
          }}
        >
          {/* First Grid */}
          <Grid lg={8} sx={{ display: "flex", flexDirection: "column", }} xl={8.5} xs={12} sm={12} md={12}>
            <Grid lg={12} sx={{ display: "flex", flexDirection: "row", justifyContent:"space-between", flexWrap:'wrap'}} sm={12} xs={12} xl={12} md={12}>
              <Grid lg={4.5} xs={12} sm={12} xl={4} md={4.5}> 
                <LivePriceCard />
              </Grid>
              <Grid lg={7.3} xs={12} sm={12} xl={7.5} md={7.3}><AnalyticsCard/></Grid>
            </Grid>
            <Grid lg={0} md={0} sm={0} xl={12} xs={0} >
              {HideReferCard?null:<ReferNowCard/>}
            </Grid>
          </Grid>


          <Grid  lg={3.7} sm={12} xs={12} xl={3.3} gap={'6px'} sx={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap',gap:'20px'}}>
          {/* <Grid lg={12} xl={12} sm={12} xs={12} md={5.8}>
          < TopRefferels />
          </Grid> */}
          <Grid lg={12} xl={12} sm={12} md={5.8} xs={12}>
          <Conversion />
          </Grid>
          </Grid>
        </Grid>
       </Grid>
      </Grid>
    
  );
};

export default DashboardPage;
