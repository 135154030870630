import React from "react";
import { Grid, Button } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { deleteUserAccount } from "../services/Profileapis";

const DeleteUser: React.FC = () => {
  const { theme } = useTheme();

  const handleAccountRemoval = async () => {
    try {
      const response = await deleteUserAccount();
      if (response) {
        window.location.href = "/signup";
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Grid
      lg={12}
      md={12}
      sm={12}
      xs={12}
      xl={12}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <label
        style={{
          fontSize: "25px",
          color: theme.palette.text.primary,
          fontWeight: 500,
        }}
      >
        Are you sure you want to delete your account?
      </label>
      <Button
        variant="contained"
        sx={{
          fontSize: "18px",
          fontWeight: 500,
          color: "#FFFFFF",
          lineHeight: "24px",
          textAlign: "center",
          borderRadius: "16px",
          paddingX: "35px",
          paddingY: "10px",
          textTransform: "initial",
          marginTop: "20px",
          bgcolor: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        }}
        onClick={handleAccountRemoval}
      >
        Delete
      </Button>
    </Grid>
  );
};
export default DeleteUser;
