import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from "../assets/themeContext";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ProfileSetting from "../assets/icons/ProfileSettings";
import { UserRound } from 'lucide-react';

const Faq = () => {
    const { theme } = useTheme();
    const navigate = useNavigate()
    const [isMapMinimized, setIsMapMinimized] = useState(false);

    const toggleMapVisibility = () => {
        setIsMapMinimized(!isMapMinimized);
      };
    

    const rows = [
      { name: "Profile", bankAccountNum: "Profile setup,verification & settings", icon: <ProfileSetting /> },
      // { name: "Rewards", bankAccountNum: "Referal program, bonuses & perks", icon: <UserRound /> },
    ];
  
    return (
      <Grid 
        container
        sx={{
          padding: "20px",
          borderRadius: "20px",
          bgcolor: theme.palette.info.light,
        }}
        item
        lg={12}
        md={12}
        xs={12}
        sm={12}
      >
        <Grid 
          container 
          sx={{ 
            display: 'flex', 
            flexDirection:'row', 
            justifyContent:'space-between',
            paddingX: '10px' 
          }}
        >
          <Grid>
            <label
              style={{
                flex: 1,
                fontSize: "24px",
                fontWeight: 700,
                color: theme.palette.primary.main,
              }}
            >
              FAQ
            </label>
          </Grid>
          <Grid sx={{  display: 'flex',justifyContent: 'flex-end' }}>
            <KeyboardArrowUpIcon 
              color="primary"
              fontSize="medium"
              cursor= 'pointer'
              onClick={toggleMapVisibility}
            />
          </Grid>
        </Grid>
        <Grid item lg={12} paddingY={'10px'}>
          <label
            style={{
              flex: 1,
              fontSize: "16px",
              fontWeight: 400,
              color: theme.palette.info.main,
            }}
          >
            Get basic help from the following frequently asked quetions
          </label>
        </Grid>
        {!isMapMinimized && (
        <Grid paddingTop={2} item lg={12} sm={12} xs={12}>
          {rows.map((row, index) => (
            <Grid key={index} sx={{ paddingY: "0px" }}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent="space-between"
                sx={{
                  padding: "20px",
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  transition: 'box-shadow 0.3s ease',
                  boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
                  marginBottom: '10px',
                  bgcolor: theme.palette.info.light,
                  cursor:"pointer"

                }}
              >
                <Box display="flex" alignItems="center" onClick={()=>navigate('/faq')}>
                  <Grid sx={{ 
                    display: 'flex', 
                    width: '40px', 
                    height: '40px', 
                    borderRadius: '30px', 
                    justifyContent: 'center',
                    backgroundColor:'rgba(109, 30, 212, 0.4)', 
                    alignItems: 'center',
                    color:'#6D1ED4', 
                    cursor:"pointer"
                  }}>
                    {row.icon}
                  </Grid>
                  <Grid sx={{ 
                    display: "flex", 
                    flexDirection: 'column', 
                    flex: 1, 
                    paddingLeft: '4px',
                    cursor:"pointer"
                  }}>
                    <Grid>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: theme.palette.primary.main,
                          cursor:"pointer"
                        }}
                      >
                        {row.name}
                      </label>
                    </Grid>
                    <Grid>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: theme.palette.info.main,
                          cursor:"pointer"
                        }}
                      >
                        {row.bankAccountNum}
                      </label>
                    </Grid>
                  </Grid>
                </Box>
                <Grid sx={{ display: 'flex', alignItems: 'center', color: theme.palette.info.main }} onClick={()=>navigate('/faq')}>
                  <ArrowForwardIosIcon 
                    fontSize="small"
                  />
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
        )}
     
    </Grid>
  );
};

  
  export default Faq;