import * as React from 'react';
import { BarChart} from '@mui/x-charts';
import { useTheme } from '../assets/themeContext';
import {useTheme as T, useMediaQuery} from '@mui/material'
import { getTransactionHistory } from "../services/Transactionapis"; // Adjust the path as needed.
import { useState, useEffect } from 'react';
interface TotalBarChartProps {
  filter: string;
}

export default function TotalBarChart({ filter }: TotalBarChartProps) {
    const {theme}  =useTheme()
    const t=T();
    const isLarge = useMediaQuery(t.breakpoints.down("lg"));
    const isMedium = useMediaQuery(t.breakpoints.down("md"));
    const isSmall = useMediaQuery(t.breakpoints.down("sm"));
    const [chartData, setChartData] = useState<number[]>([]);
      const [months, setMonths] = useState<string[]>([]);
      const [loading, setLoading] = useState<boolean>(true);
    
      const getFilteredMonths = (filter: string): string[] => {
        const currentDate = new Date();
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December",
        ];
    
        let months = [];
        let range = 0;
    
        if (filter === "1 M") {
          range = 0;
        } else if (filter === "3 M") {
          range = 2;
        } else if (filter === "6 M") {
          range = 5;
        }
    
        for (let i = range; i >= 0; i--) {
          const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
          months.push(monthNames[date.getMonth()]);
        }
        return months;
      };
    
      const fetchTransactionData = async () => {
        try {
          setLoading(true);
          const data = await getTransactionHistory();
    
          const filteredMonths = getFilteredMonths(filter);
          const usdData: number[] = [];
          const monthLabels: string[] = [];
    
          filteredMonths.forEach((month) => {
            if (data.month[month]) {
              usdData.push(data.month[month].usd_transfer_sum || 0);
              monthLabels.push(month.substring(0, 3));
            }
          });
    
          setChartData(usdData);
          setMonths(monthLabels);
        } catch (error) {
          console.error("Error fetching transaction data:", error);
        } finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        fetchTransactionData();
      }, [filter]); 
    
  return (
    <BarChart
      width={isSmall ? 300 : isMedium ? 500 : isLarge ? 500 : 500}
      height={isMedium?250:310}
      series={[
        { data: chartData, label: 'Transfers', color:'#6D1ED4'},
      ]}
      xAxis={[{ scaleType: 'band', data: months}]}
      leftAxis={null}
      slotProps={{ legend: { hidden: true } }}
      axisHighlight={{
        x: 'line', 
        y: 'none', 
      }}
      sx={{
        // bottomAxis Line Styles
        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
            strokeWidth: 0,
          },
          "& .MuiChartsAxis-tick": {
            strokeWidth: 0,
          },
          "& .MuiChartsAxis-tickLabel tspan": {
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "20px",
            color: theme.palette.info.main,
            fill: theme.palette.info.main,
          },
          "& .recharts-line .recharts-line-0, & .recharts-line .recharts-line-1": {
            strokeWidth: "3px", // Increase width by 2px
          },
      }}
    />
  );
}