import React, { useState, useEffect } from "react";
import { Grid, Input, MenuItem, Select, Box, useTheme as T, useMediaQuery, ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  Calendar,
  ChevronUp,
  ChevronDown,
  BarChart2,
  LineChartIcon,
} from "lucide-react";
import { useTheme } from "../assets/themeContext";
import TotalLineChart from "./LineChart";
import TotalBarChart from "./TotalBarChart";
import { getTransactionHistory } from "../services/Transactionapis";

const AnalyticsCard: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const isMedium = useMediaQuery(t.breakpoints.down('md'));
  const isLarge = useMediaQuery(t.breakpoints.down('lg'));
  const isSmall = useMediaQuery(t.breakpoints.down("sm"));
  const filterOptions = [
    { id: "1 M", value: 0 },
    { id: "3 M", value: 3 },
    { id: "6 M", value: 6 },
  ];

  const [filter, setFilter] = useState<string>(filterOptions[2].id);
  const [lineChart, isLineChart] = useState(false);
  const [transactionData, setTransactionData] = useState<{
    month: Record<string, { usd_transfer_sum: number; inr_transfer_sum: number }>;
    summary: Record<string, { usd_transfer_sum: number; inr_transfer_sum: number }>;
  } | null>(null);
  const fetchData = async () => {
    try {
      const data = await getTransactionHistory();
      setTransactionData(data);
    } catch (error) {
      console.error("Error fetching transaction history:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleChart = () => {
    isLineChart(!lineChart);
  };

  // Percentage Color
  const percentage = 2.3;
  const percentageSymbol = percentage > 0 ? "+" : "";

  // Get the selected filter value
  const selectedFilter = filterOptions.find((option) => option.id === filter);
  const summaryKey = selectedFilter ? selectedFilter.value.toString() : "0";
  const summaryData = transactionData?.summary[summaryKey] || {
    usd_transfer_sum: 0,
    inr_transfer_sum: 0,
  };

  return (
    <Grid
      container
      lg={12}
      sm={12}
      md={12}
      xs={12}
      xl={12}
      sx={{
        bgcolor: theme.palette.info.light,
        height: isMedium ? "95%" : "100%",
        padding: "20px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "row",
        flexWrap: 'wrap',
        gap: "10px",
        flex: "1"
      }}
      marginTop={isMedium ? '20px' : 'null'}
    >
      <Grid lg={12} md={12} xs={12} sm={12} xl={3} sx={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap", alignItems: "center", gap: isSmall ? "40px" : "120px" }}>
        <Grid>
          {/* <Select
            style={{
              color: theme.palette.info.main,
              fontFamily: "Inter",
              padding: "5px",
              backgroundColor: theme.palette.primary.dark,
              borderRadius: "7px",
              boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            input={
              <Input
                startAdornment={
                  <Calendar
                    style={{
                      height: "25px",
                      width: "25px",
                      marginRight: "5px",
                      marginLeft: "5px",
                    }}
                    strokeWidth={2}
                  />
                }
              />
            }
            variant="standard"
            value={filter}
            label="month"
            onChange={(e) => setFilter(e.target.value)}
            disableUnderline
            sx={{
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&:hover": {
                backgroundColor: "transparent", 
              },
              "& .MuiSelect-select": {
                "&:focus": {
                  backgroundColor: "transparent", 
                },
              },
              "& .MuiSelect-icon": {
                color: theme.palette.info.main,
                display: "none",
              },

              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              color: theme.palette.info.main,
            }}
          >
            {filterOptions.map((item) => (
              <MenuItem
                value={item.id}
                key={item.id}
                sx={{
                  borderRadius: "1px",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: theme.palette.info.main,
                  backgroundColor: theme.palette.info.light,
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: "#FFFFFF",
                    },
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: "#FFFFFF",
                  },
                }}
              >
                {item.id}
              </MenuItem>
            ))}
          </Select> */}
          <ToggleButtonGroup
              value={filter}
              exclusive
              onChange={(e, newFilter) => {
                if (newFilter !== null) {
                  setFilter(newFilter);
                }
              }}
              sx={{
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "7px",
                boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
                // padding: "5px",
              }}
            >
              {filterOptions.map((item) => (
                <ToggleButton
                  key={item.id}
                  value={item.id}
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: theme.palette.info.main,
                    backgroundColor: theme.palette.info.light,
                    border: "none",
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                      },
                    },
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: "#FFFFFF",
                    },
                  }}
                >
                  {item.id}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
        </Grid>

        <Grid
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Grid>
            <label
              style={{
                fontSize: isLarge ? '25px' : "28px",
                fontWeight: 700,
                lineHeight: "42px",
                color: "#6D1ED4",
              }}
            >
              {`$ ${summaryData.usd_transfer_sum.toFixed(2)}`}
            </label>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: 500,
                lineHeight: "24px",
                color: theme.palette.info.main,
              }}
            >
              Total Transfers
            </label>
            {/* <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {percentage > 0 ? (
                <ChevronUp
                  style={{ height: "15px", width: "15px", color: "#6D1ED4" }}
                />
              ) : (
                <ChevronDown
                  style={{ height: "15px", width: "15px", color: "#6D1ED4" }}
                />
              )}
              <label
                style={{
                  fontSize: "12px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: "#6D1ED4",
                  padding: "1px 0px",
                }}
              >
                {percentageSymbol}
                {percentage}
                {"%"}
              </label>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        lg={12}
        md={12}
        xs={12}
        sm={12}
        xl={8.8}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Grid sx={{ alignSelf: "flex-end", }}>
          <Box
            sx={{
              padding: "3px 6px",
              bgcolor: theme.palette.primary.dark,
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={handleChart}
          >
            {lineChart ? (
              <LineChartIcon
                style={{
                  width: "22px",
                  height: "22px",
                  color: "rgba(109, 30, 212, 1)",
                }}
                strokeWidth={2.5}
              />
            ) : (
              <BarChart2
                style={{
                  width: "22px",
                  height: "22px",
                  color: "rgba(109, 30, 212, 1)",
                }}
                strokeWidth={2.5}
              />
            )}
          </Box>
        </Grid>
        <Grid container sx={{ width: "100%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
            <Box sx={{ width: "100%", minHeight: "250px", alignItems: "center" }}>
                {lineChart ? <TotalBarChart filter={filter}/> : <TotalLineChart filter={filter}/>}
            </Box>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
};

export default AnalyticsCard;