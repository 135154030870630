import React from "react";
import { Grid, Box, Button, useMediaQuery, useTheme } from "@mui/material";
import ReferImage from "../assets/icons/ReferImage";
import { useNavigate } from "react-router-dom";

const ReferralCard: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.between("md", "lg"));
  return (
    <Grid
      container
      lg={12}
      sm={12}
      md={12}
      xs={12}
      xl={12}
      sx={{
        background: "linear-gradient(to bottom , #6D5290,#6D1ED4 )",
        borderRadius: "20px",
        paddingX: "20px",
        paddingTop: "30px",
        display: "flex",
        flexDirection: "column",
        minHeight: isMedium ? "400px" : "auto",
      }}
    >
      <Grid>
        <Box>
          <label
            style={{
              fontSize: "25px",
              fontWeight: 600,
              lineHeight: "42px",
              color: "#FFFFFF",
            }}
          >
            "Need Help? We’re Here for You!"
          </label>
        </Box>

        {/* <Box>
            <label
             style={{
                fontSize:'25px',
                fontWeight:600,
                lineHeight:'30px',
                color:'#FFFFFF'
             }}
            >
              exclusive rewards
            </label>
            </Box> */}
      </Grid>

      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        width={"100%"}
        lg={10}
        md={10}
        sm={10}
        xs={10}
        xl={10}
      >
        <Grid>
          <Button
            variant="contained"
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              color: "#1F1F1F",
              lineHeight: "25px",
              textAlign: "center",
              borderRadius: "16px",
              paddingX: "28px",
              width: "120px",
              paddingY: "12px",
              marginTop: "25px",
              alignSelf: "center",
              textTransform: "initial",
              bgcolor: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#FFFFFF",
              },
            }}
            onClick={() => navigate("/support")}
          >
            Contact
          </Button>
        </Grid>
        <Grid>
          <ReferImage height={250} width={170} viewBox="0 0 200 350" />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ReferralCard;
