import React, { useState, useEffect } from "react";
import { Grid, Box, Radio, Button, Dialog, DialogContent } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { Landmark, EllipsisVertical } from "lucide-react";
import { Menu, MenuItem } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddpaymentMethod from "./AddpaymentMethods";
import {
  fetchPaymentMethods,
  deletePaymentMethodApi,
} from "../services/Paymentapis";
import { toast } from "react-toastify";

interface PaymentMethod {
  _id: string;
  user_id: string;
  method_type: string;
  details: {
    account_number?: string;
    routing_number?: string;
    bank_name?: string;
    card_number?: string;
    expiry?: string;
    cvv?: string;
    cardholder?: string;
  };
  is_active: boolean;
  created_at: string;
  last_used_at: string | null;
  is_deleted: boolean;
}

interface MappedTransaction {
  name: string;
  date: string;
  UserName: number | string;
  Type: string;
  Code: string;
  paymentMethodId: string;
}

const PaymentsMethods: React.FC = () => {
  const { theme } = useTheme();
  const [selectedRow, setSelectedRow] = useState<number | null>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [clickedRow, setClickedRow] = useState<number | null>(0);
  const [openRecipients, setOpenRecipients] = React.useState<boolean>(false);
  const [dataCount, setdataCount] = React.useState(0);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<
    number | null
  >(null);
  const [PaymentMethod, setPaymentMethod] = useState<MappedTransaction[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPaymentMethod();
  }, []);

  const fetchPaymentMethod = async () => {
    try {
      const result = await fetchPaymentMethods();
      const mappedData: MappedTransaction[] = result?.data?.map(
        (PaymentMethod: PaymentMethod) => ({
          paymentMethodId: PaymentMethod._id,
          name: PaymentMethod.details.bank_name,
          date: PaymentMethod.details.card_number,
          UserName: PaymentMethod.details.cardholder,
          Type: PaymentMethod.method_type,
          Code: PaymentMethod.details.cvv,
        })
      );
      // Set the mapped data to state
      setPaymentMethod(mappedData);
      // console.log("for delete", mappedData);
    } catch (err) {
      console.error(err);
    }
  };

  const deletePaymentMethodApis = async (paymentMethodId: string | number) => {
    try {
      setLoading(true);

      await deletePaymentMethodApi(paymentMethodId);
      toast.success("Paymentmethod Deleted Successfully", {
        autoClose: 2000,
      });
      fetchPaymentMethod();
    } catch (err) {
      // Handle errors
      console.error("Error deleting payment method:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleRowSelect = (paymentMethodId: number) => {
    setSelectedRow(paymentMethodId);
    setSelectedPaymentMethodId(paymentMethodId);
  };

  const handleCardClick = (paymentMethodId: number) => {
    setClickedRow(paymentMethodId);
    setSelectedRow(paymentMethodId); // Update selectedRow when a card is clicked
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
        height: "100%",
      }}
      lg={12}
      md={12}
      xs={12}
      sm={12}
      xl={12}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid paddingTop={2}>
          <label
            style={{
              fontSize: "20px",
              fontWeight: 500,
              color: theme.palette.primary.main,
            }}
          >
            Payment Method
          </label>
        </Grid>
        <Grid paddingTop={0}>
          <Button
            sx={{
              textAlign: "center",
              borderRadius: "30px",
              paddingX: " 18px",
              textTransform: "initial",
              cursor: "pointer",
              paddingY: "6px",
              bgcolor: "#F4F7FE",
              "&:hover": {
                backgroundColor: "#F4F7FE",
              },
            }}
            onClick={() => setOpenRecipients(true)}
          >
            <label
              style={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "32px",
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            >
              Add Payment Methods
            </label>
          </Button>
        </Grid>
      </Grid>
      <Grid paddingTop={2}>
        {PaymentMethod.map((row, paymentMethodId) => (
          <Grid
            key={paymentMethodId}
            sx={{ paddingY: "3px", paddingX: "10px" }}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{
                padding: "15px 0px",
                backgroundColor: "white",
                borderRadius: "10px",
                bgcolor: theme.palette.info.light,
                transition: "box-shadow 0.3s ease",
                marginBottom: "10px",
                boxShadow:
                  clickedRow === paymentMethodId
                    ? theme.palette.action.hover
                    : null,
              }}
              onClick={() => handleCardClick(paymentMethodId)}
            >
              <Grid sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    paddingY: "21px",
                  }}
                >
                  <Landmark
                    size={30}
                    style={{
                      marginLeft: "10px",
                      marginRight: "12px",
                      color: theme.palette.text.primary,
                    }}
                  />
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <label
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            color: theme.palette.text.primary,
                            textTransform: "capitalize",
                          }}
                        >
                          {row.name || row?.Type}
                        </label>

                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: theme.palette.info.main,
                          }}
                        >
                          {row.date}
                        </label>
                      </Grid>
                      <Grid>
                        <Radio
                          style={{ color: theme.palette.info.main }}
                          checked={selectedRow === paymentMethodId}
                          onChange={() => handleRowSelect(paymentMethodId)}
                        />
                      </Grid>
                      <Grid>
                        <IconButton onClick={handleMenuClick}>
                          <EllipsisVertical
                            style={{ color: theme.palette.info.main }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {selectedRow === paymentMethodId && (
                  <Grid
                    sx={{
                      marginLeft: "15px",
                      display: "flex",
                      paddingTop: "20px",
                      color: theme.palette.primary.main,
                      borderRadius: "5px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <label
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          color: theme.palette.text.primary,
                        }}
                      >
                        Name
                      </label>
                      <br />
                      <label
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: theme.palette.info.main,
                        }}
                      >
                        {row.UserName}
                      </label>
                    </Grid>
                    <Grid>
                      <label
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          color: theme.palette.text.primary,
                        }}
                      >
                        Type
                      </label>
                      <br />
                      <label
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: theme.palette.info.main,
                        }}
                      >
                        {row.Type}
                      </label>
                    </Grid>
                    <Grid paddingRight={"20px"}>
                      <label
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          color: theme.palette.text.primary,
                        }}
                      >
                        IFSC Code
                      </label>
                      <br />
                      <label
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: theme.palette.info.main,
                        }}
                      >
                        {row.Code}
                      </label>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => deletePaymentMethodApis(row.paymentMethodId)}
                >
                  <Delete />
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={openRecipients}
        onClose={() => setOpenRecipients(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { borderRadius: "20px" } }}
      >
        <Box
          sx={{
            bgcolor: theme.palette.primary.dark,
            textAlign: "center",
            p: 2,
          }}
        >
          <label
            style={{
              color: theme.palette.primary.main,
              fontSize: "19px",
              fontWeight: 700,
            }}
          >
            {" "}
            Add Payment Method
          </label>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => setOpenRecipients(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{ color: theme.palette.text.primary }} />
        </IconButton>
        <DialogContent sx={{ bgcolor: theme.palette.primary.dark }}>
          <AddpaymentMethod setOpenRecipients={setOpenRecipients} />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default PaymentsMethods;