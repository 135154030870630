import React from "react";
import { Grid, Box, Button} from "@mui/material";
import ReferImage from "../assets/icons/ReferImage";
import { useNavigate } from "react-router-dom";

const ReferNowCard: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Grid
      marginTop={"20px"}
      lg={12}
      md={12}
      xl={12}
      sx={{
        background: "linear-gradient(to bottom , #6D5290,#6D1ED4 )",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Grid lg={9} md={9} sx={{paddingX: "50px",
        paddingTop: "50px",
        }}>
        <Grid lg={12} md={12}>
          <Box>
            <label
              style={{
                fontSize: "34px",
                fontWeight: 700,
                lineHeight: "42px",
                color: "#FFFFFF",
              }}
            >
             "Need Help? We’re Here for You!"
            </label>
          </Box>
          {/* <Box>
            <label
              style={{
                fontSize: "34px",
                fontWeight: 700,
                lineHeight: "42px",
                color: "#FFFFFF",
              }}
            >
              exclusive rewards
            </label>
          </Box> */}
        </Grid>
        <Grid lg={12} md={12} paddingTop={"25px"} sx={{display:'flex', flexDirection:'column'}}>
          <Grid>
          <label
            style={{
              fontSize: "15px",
              fontWeight: 500,
              lineHeight: "28px",
              color: "#E3DAFF",
            }}
          >
            Have questions or need assistance?
          </label>
          </Grid>
          <Grid>
          <label
            style={{
              fontSize: "15px",
              fontWeight: 500,
              lineHeight: "28px",
              color: "#E3DAFF",
            }}
          >
             Visit our FAQ & Support page for quick answers and guidance.
          </label>
          </Grid>
        </Grid>
        <Grid
          display={"flex"}
          flexDirection={"row"}
          gap={'35px'}
          alignItems={"center"}
          paddingY={"30px"}
        >
          <Grid>
            <Button
              variant="contained"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#1F1F1F",
                lineHeight: "24px",
                textAlign: "center",
                borderRadius: "16px",
                paddingX: "35px",
                paddingY: "10px",
                textTransform: "initial",
                bgcolor: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
              }}
              onClick={() => navigate("/faq")}
            >
              Explore Support
            </Button>
          </Grid>
          {/* <Grid>
            <label
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "28px",
                color: "#F6F4F9",
                cursor: "pointer",
              }}
            >
              Learn More
            </label>
          </Grid> */}
        </Grid>
      </Grid>
      <Grid marginTop={'30px'}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
        lg={5}
        md={5}
      >
        <div>
          <ReferImage height={300} width={340} viewBox="0 0 262 330"/>
        </div>
      </Grid>
    </Grid>
  );
};
export default ReferNowCard;
