import React, { useEffect } from "react";
import NavBar from "../components/HeaderBar"; // Import your NavBar component
import { Grid } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import CurrencyConverter from "../components/CurrencyConverter";
import TransactionTable from "../components/TransactionTable";
import FrequentRecipient from "./FrequentReceipients";
import ReferralCard from "../components/ReferralCard";

const Transactions: React.FC = () => {
  const { theme } = useTheme();

  useEffect(() => {
    document.title = "AptRemit-Transaction";
  });

  return (
    <Grid
      //lg={12}
      sx={{ backgroundColor: theme.palette.primary.dark, height: "100vh" }}
    >
      <NavBar highlightDashboard={true} /> {/* Render NavBar component */}
      <Grid
        sx={{ backgroundColor: theme.palette.primary.dark }}
        paddingX={"20px"}
      >
        <label
          style={{
            color: theme.palette.primary.main,
            fontSize: "34px",
            fontWeight: 700,
            lineHeight: "42px",
          }}
        >
          Transactions Page
        </label>
        <Grid
          paddingY={"20px"}
          lg={12}
          sm={12}
          xs={12}
          md={12}
          xl={12}
          container
          sx={{
            justifyContent: 'space-around',
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          <Grid lg={8} md={12} xl={8} sm={12} xs={12}>
            <TransactionTable/>
          </Grid>
          <Grid lg={3.5} md={12} xl={3.5} sm={12} xs={12} sx={{display:'flex', flexDirection:"row", flexWrap:'wrap', gap:'20px', justifyContent:'space-between'}}>
           {/* <Grid lg={12} md={5.8} xl={12} sm={12} xs={12}>
           <FrequentRecipient/>
           </Grid>
            <Grid lg={12} md={5.8} xl={12} sm={12} xs={12} >
            <ReferralCard />
            </Grid> */}
                       
           <Grid sm={12} md={12} xl={12} xs={12} lg={12} >
            <CurrencyConverter/>
            </Grid> 
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Transactions;
