const authApi = "https://api-dev.aptremit.io/auth-api";
//const token = sessionStorage.getItem('access_token')

export const Login = async (userData) => {
  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    username: userData.username,
    password: userData.password,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(`${authApi}/api/auth/login`, requestOptions);
  const result = await response.json();
  if (result.access_token) {
    return {
      success: true,
      access_token: result.access_token,
      refresh_token: result.refresh_token,
      message: "Login Success",
    };
  } else {
    return {
      success: false,
      message: result.detail,
    };
  }
};

export const SignUp = async (userData) => {
  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  console.log(userData);

  const raw = JSON.stringify({
    first_name: userData.firstname,
    middle_name: userData.middlename,
    last_name: userData.lastname,
    email: userData.username,
    password: userData.password,
    dob: userData.dob,
    us_mobile_number: userData.countryCode + userData.phone,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(`${authApi}/api/auth/register`, requestOptions);
  const result = await response.json();
  if (result) {
    return {
      success: true,
      // access_token: result.access_token,
      // refresh_token: result.refresh_token,
      message: "Login Success",
    };
  } else {
    return {
      success: false,
      message: result.detail,
    };
  }
};

export const getAccess = async (refreshToken) => {
  try {
    const response = await fetch(`${authApi}/api/auth/refresh`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("could not fetch access token", error);
    return error;
  }
};

export const supportIssues = async (data, file) => {
  console.log("Submitting Support Ticket Data:", data);

  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("category", data.category);
    formData.append("email", data.email);

    // Append file if present
    if (file) {
      formData.append("file", file);
      console.log(file);
    }

    const response = await fetch(`${authApi}/api/support/issues`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`, // No Content-Type needed for FormData
      },
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP ${response.status}: ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error creating support ticket:", error.message);
    throw error;
  }
};

export const getSupportIssues = async (page) => {
  try {
    const pages = (page - 1) * 10;
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(`${authApi}/api/support/users_issues`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch recent recipients:", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};

export const sendOtp = async (userId) => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const raw  = {
      recipient_id : userId
    }
    const response = await fetch(`${authApi}/api/otp/send-otp/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not send OTP:", error);
    throw error;
  }
};


export const verifyOtp = async (userId, otp) => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const raw  = {
      recipient_id : userId,
      otp : otp
    }
    const response = await fetch(`${authApi}/api/otp/verify-otp/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.error("Could not verify OTP:", error);
    throw error;
  }
}