import React, { useEffect } from "react";
import NavBar from "../components/HeaderBar"; // Import your NavBar component
import { Grid } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import UserCard from "../components/UserCard";
import UserReferral from "../components/UserReferral";
import RecentActivity from "../components/recentActivity";
import Settings from "../components/Settings";
import Faq from "../components/Faq";
import ReferralCard from "../components/ReferralCard";
import FrequentRecipient from "../components/FrequentReceipients";

const Profile: React.FC = () => {
  const { theme } = useTheme();

  useEffect(() => {
    document.title = "AptRemit-Profile";
  });

  return (
    <Grid
      lg={12}
      md={12}
      sx={{ backgroundColor: theme.palette.primary.dark, height: "100vh" }}
    >
      <NavBar highlightDashboard={true} /> {/* Render NavBar component */}
      <Grid sx={{ backgroundColor: theme.palette.primary.dark }}>
        <label
          style={{
            color: theme.palette.primary.main,
            fontSize: "34px",
            fontWeight: 700,
            lineHeight: "42px",
            padding: "20px",
          }}
        >
          Profile
        </label>
        {/* Add your dashboard content here */}
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          xl={12}
          padding={"20px"}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <Grid
            lg={3.5}
            md={12}
            xs={12}
            sm={12}
            xl={3.5}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Grid lg={12} md={5.5} xs={12} sm={5.5} xl={12}>
              <UserCard />
            </Grid>
            <Grid lg={12} md={5.8} xs={12} sm={6} xl={12}>
              <ReferralCard />
            </Grid>
          </Grid>

          <Grid lg={5} xl={4.5} xs={12} md={12} sm={12}>
            {/* <RecentActivity /> */}
            <FrequentRecipient/>
          </Grid>

          <Grid
            lg={3}
            md={12}
            xs={12}
            sm={12}
            xl={3.5}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Grid lg={12} md={5.5} xs={12} sm={5.5} xl={12}>
              <Settings />
            </Grid>
            <Grid lg={12} md={5.5} xs={12} sm={5.5} xl={12}>
              <Faq />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
