import React, { useEffect, useState } from "react";
import { Grid, Box, Avatar, useTheme as T, useMediaQuery } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { getFrequentRecipient } from "../services/RecipientApis";
import { useNavigate } from "react-router-dom";
import ProgressLoader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import { selectCard, deslectCard } from "../redux/slices/chooseRecSlice";
import { RootState } from "../redux/reducers/reducers";
interface Recipient {
  _id: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  account_number: string;
  is_upi: boolean;
  upi_id: string;
}

interface MappedFrequentRecipient {
  rowId: any;
  recipientID: string;
  name: string;
  account: string;
  paymentMethods: string;
  paymentThrough: string;
}
interface RecipientInfo {
  id: any;
  name: string;
  paymentMethod: string;
}

const FrequentRecipient = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const t = T();
  const isMedium = useMediaQuery(t.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { recipientName, paymentMethodId, recipientId } = useSelector(
    (state: RootState) => state.chooseRecipient
  );
  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: HTMLElement | null;
  }>({});
  const [
    selectedRecipient,
    setSelectedRecipient,
  ] = useState<MappedFrequentRecipient | null>(null);

  const path = window.location.pathname;

  const isRecipientPage = path === "/recipients";

  const maskAccountNumber = (accNumber: string): string => {
    const visibleDigits = accNumber.slice(-4);
    const maskedDigits = "X".repeat(accNumber.length - 4);
    const formattedMaskedDigits = maskedDigits.replace(/(.{4})/g, "$1 ");
    return formattedMaskedDigits + visibleDigits;
  };

  const maskUpiNumber = (upiNumber: string): string => {
    const visibleDigits = upiNumber.slice(-6);
    const maskedPart = upiNumber.slice(0, -6).replace(/./g, "X");
    return maskedPart + visibleDigits;
  };

  const [frequentReceipient, setFrequentReceipient] = useState<
    MappedFrequentRecipient[]
  >([]);
  const getFrequentRecipients = async () => {
    setLoading(true);
    try {
      const response = await getFrequentRecipient();
      const mappedResponse: MappedFrequentRecipient[] = response.map(
        (recipient: Recipient) => ({
          recipientID: recipient?._id,

          name: recipient?.first_name,
          account: recipient.is_upi
            ? maskUpiNumber(recipient.upi_id || "")
            : maskAccountNumber(recipient.account_number || ""),
        })
      );
      setFrequentReceipient(mappedResponse);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getFrequentRecipients();
  }, []);

  const handleSecureTransfer = (row: MappedFrequentRecipient) => {
    navigate("/transactions");
    setSelectedRecipient(row);
    const userName = row?.name;
    const paymentMethod = row?.account;
    const recipientID = row?.recipientID;
    const recipientInfo: RecipientInfo = {
      id: recipientID,
      name: userName,
      paymentMethod: paymentMethod,
    };
    console.log(recipientInfo);
    dispatch(
      selectCard({
        inrAmount: "",
        usdAmount: "",
        recipientName: userName,
        paymentMethodId: paymentMethod,
        recipientId: recipientID,
      })
    );
  };

  return (
    <Grid
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
        marginTop: isMedium ? "20px" : null,
      }}
      lg={12}
      md={12}
      xs={12}
      sm={12}
      height={"100%"}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingX: "10px",
        }}
      >
        <Grid>
          <label
            style={{
              flex: 1,
              fontSize: "20px",
              fontWeight: 500,
              color: theme.palette.primary.main,
            }}
          >
            Frequent Recipients
          </label>
        </Grid>
        {!isRecipientPage && (
          <Grid onClick={() => navigate("/recipients")}>
            <label
              style={{
                paddingTop: "2px",
                flex: 1,
                fontSize: "16px",
                fontWeight: 500,
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            >
              See all
            </label>
          </Grid>
        )}
      </Grid>
      {loading ? (
        <Grid
          width={"100%"}
          height={"100%"}
          lg={12}
          sm={12}
          xs={12}
          xl={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ProgressLoader strokeColor="#6D1ED4" fillColor="#6D1ED4" />
        </Grid>
      ) : (
        <>
          {frequentReceipient.length <= 0 ? (
            <Grid>
              <Box
                marginY={15}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    paddingBottom: "20px",
                    fontSize: "20px",
                    fontWeight: 700,
                    color: theme.palette.text.primary,
                  }}
                >
                  No Frequent Recipients Available
                </label>
              </Box>
            </Grid>
          ) : (
            <Grid paddingTop={2}>
              {frequentReceipient.map((row, index) => (
                <Grid key={index} sx={{ paddingY: "0px" }}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"10px"}
                    sx={{
                      padding: "15px",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      marginBottom: "10px",
                      bgcolor: theme.palette.info.light,
                      transition: "box-shadow 0.3s ease",
                      "&:hover": {
                        boxShadow: theme.palette.action.selected,
                      },
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        bgcolor: "rgba(109, 30, 212, 0.3)",
                      }}
                    />

                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        paddingLeft: "4px",
                      }}
                    >
                      <Grid>
                        <label
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            color: theme.palette.text.primary,
                          }}
                        >
                          {row.name}
                        </label>
                      </Grid>
                      <Grid>
                        <label
                          style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            color: theme.palette.info.main,
                          }}
                        >
                          {row.account}
                        </label>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        flex: 1,
                        paddingTop: 1,
                        paddingLeft: "4px",
                        gap: "10px",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                        onClick={() => handleSecureTransfer(row)}
                      >
                        Transfer Now
                      </label>

                      {/* <label
                      
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: theme.palette.info.main,
                          cursor: 'pointer'
                        }}
                        //onClick={()}
                      >
                        manage
                      </label> */}
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default FrequentRecipient;

// <Grid sx={{ display: 'flex' , flexDirection:'row', justifyContent:'space-between',paddingX: '10px' }}>
// <Grid>
// <label
// style={{
// flex: 1,
// fontSize: "16px",
// fontWeight: 700,
// color: theme.palette.primary.main,
// }}
// >
// Frequent Recipients
// </label>
// </Grid>
// <Grid>
// <label
// style={{
// flex: 1,
// fontSize: "16px",
// fontWeight: 500,
// color: theme.palette.primary.main,
// }}
// >
// See all
// </label>
// </Grid>
// </Grid>

// <Grid sx={{ paddingY: "30px",paddingX: "5px", backgroundColor: 'white', borderRadius: '10px', boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)' }}>
// <Grid
// sx={{
// display: "flex",
// flexDirection: "row",
// justifyContent: "space-evenly",
// alignItems: "center",

// }}
// >
// <Avatar
// sx={{ width: 40, height: 40, bgcolor: "rgba(109, 30, 212, 0.3)" }}
// />
// <label
// style={{
// flex: 1,
// fontSize: "16px",
// fontWeight: 700,
// color: theme.palette.text.primary,
// }}
// >
// Jane Smith
// </label>

// <label
// style={{
// flex: 1,
// fontSize: "16px",
// fontWeight: 500,
// color: theme.palette.primary.main,
// }}
// >
// Transfer Now
// </label>

// <label
// style={{
// fontSize: "16px",
// fontWeight: 500,
// color: theme.palette.info.main,
// }}
// >
// Manage
// </label>
// </Grid>
// </Grid>
