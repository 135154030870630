import React, { useEffect, useState } from "react";
import NavBar from "../components/HeaderBar";
import {
  Grid,
  useTheme as T,
  useMediaQuery,
  Button,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  FormLabel,
  TextField,
  Select,
  Input,
  MenuItem,
  Chip,
  Pagination,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "../assets/themeContext";
import { supportIssues } from "../services/Authapis";
import { toast } from "react-toastify";
import { getSupportIssues } from "../services/Authapis";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import ProgressLoader from "../components/Loader";
import { format } from "date-fns";
interface MappedSupportIssue {
  rowId: any;
  title: string;
  category: string;
  status: string;
  createdAt: string;
  userEmail: string;
}

interface SupportIssue {
  id: string;
  title: string;
  description: string;
  images: string[];
  category: string;
  user_id: string;
  email: string;
  status: string;
  created_time: string;
  updated_time: string;
  closed_time: string | null;
  responses: any[];
}
interface Errors {
  mail?: string | null;
  password?: string | null; // field for password validation error
}

export function SortedDescendingIcon() {
  const { theme } = useTheme();
  return <ExpandMoreIcon sx={{ color: theme.palette.info.main }} />;
}

export function SortedAscendingIcon() {
  const { theme } = useTheme();
  return <ExpandLessIcon sx={{ color: theme.palette.info.main }} />;
}

const Support: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const isLarge = useMediaQuery(t.breakpoints.down("md"));
  const [ticketData, setTicketData] = useState<MappedSupportIssue[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = React.useState(1);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [loading, setLoading] = useState<boolean>(false);

  // const validateEmail = (text: string): boolean => {

  //   if (!text) {
  //     setErrors((prev) => ({ ...prev, mail: "Email is required" }));
  //     return false;
  //   } else {
  //     const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  //     if (!emailPattern.test(text)) {
  //       setErrors((prev) => ({ ...prev, mail: "Invalid email format" }));
  //       return false;
  //     } else {
  //       setErrors((prev) => ({ ...prev, mail: null }));
  //       return true;
  //     }
  //   }
  // };

  const filterOptions = [
    { id: "Profile", value: "profile" },
    { id: "Recipients", value: "recipients" },
    { id: "Transactions", value: "transactions" },
    { id: "Payment Methods", value: "paymentMethods" },
  ];
  const [createTicket, setCreateTicket] = React.useState<boolean>(false);
  const [filter, setFilter] = useState<string>(filterOptions[2].id);
  const [description, setDescription] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const [file, setFile] = React.useState<File | null>(null);
  const [mail, setMail] = React.useState<string>("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = { title, description, category: filter, email: mail };
      const response = await supportIssues(data, file);
      setTitle("");
      setDescription("");
      setMail("");
      setFile(null);
      setFilter(filterOptions[2].id);
      setCreateTicket(false);
      toast.success("Support ticket created", {
        autoClose: 2000,
      });
      await fetchSupportIssues(page);
    } catch (error) {
      console.error("Error submitting support ticket:", error);
      toast.error("Error creating ticket", {
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = "AptRemit-Support";
  });

  const fetchSupportIssues = async (page: number) => {
    setLoading(true);
    try {
      const result = await getSupportIssues(page);

      if (!result || !Array.isArray(result.issues)) {
        throw new Error("Invalid response data format");
      }

      const sortedIssues = result.issues.sort(
        (a: SupportIssue, b: SupportIssue) => {
          return (
            new Date(b.created_time).getTime() -
            new Date(a.created_time).getTime()
          );
        }
      );

      setCount(result.count);

      const mappedData: MappedSupportIssue[] = sortedIssues.map(
        (issue: SupportIssue, index: number) => ({
          rowId: index,
          title: issue.title,
          category: issue.category,
          status: issue.status,
          createdAt: format(new Date(issue.created_time), "dd/MM/yyyy"),
          userEmail: issue.email,
        })
      );
      setTicketData(mappedData);
    } catch (error) {
      console.error("Error fetching support issues:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSupportIssues(page);
  }, [page]);

  const isXs = useMediaQuery(t.breakpoints.only("xs"));
  const isSm = useMediaQuery(t.breakpoints.only("sm"));
  const isMd = useMediaQuery(t.breakpoints.only("md"));
  const isLg = useMediaQuery(t.breakpoints.only("lg"));
  const isXl = useMediaQuery(t.breakpoints.only("xl"));

  const getColumnWidth = (column: string) => {
    switch (column) {
      case "title":
        if (isXs) return 200;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return undefined;
        if (isXl) return undefined;
        return undefined;

      case "category":
        if (isXs) return 200;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return undefined;
        if (isXl) return undefined;
        return undefined;

      case "userEmail":
        if (isXs) return 200;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return undefined;
        if (isXl) return undefined;
        return undefined;

      case "createdAt":
        if (isXs) return 100;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return undefined;
        if (isXl) return undefined;
        return undefined;

      case "status":
        if (isXs) return 100;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return undefined;
        if (isXl) return undefined;
        return undefined;
      default:
        return undefined;
    }
  };

  // get flex based on breakpoints and columns
  const getColumnFlex = (column: string) => {
    switch (column) {
      case "title":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return 1;
        if (isXl) return 1;
        return undefined;

      case "category":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return 1;
        if (isXl) return 1;
        return undefined;

      case "userEmail":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return 1;
        if (isXl) return 1;
        return undefined;

      case "createdAt":
        if (isXs) return undefined;
        if (isSm) return 0.5;
        if (isMd) return 0.5;
        if (isLg) return 0.5;
        if (isXl) return 0.5;
        return undefined;

      case "status":
        if (isXs) return undefined;
        if (isSm) return 0.5;
        if (isMd) return 0.5;
        if (isLg) return 0.5;
        if (isXl) return 0.5;
        return undefined;

      default:
        return undefined;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      width: getColumnWidth("title"),
      flex: getColumnFlex("title"),
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary }}>
          {params.value}
        </label>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      width: getColumnWidth("category"),
      flex: getColumnFlex("category"),
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary }}>
          {params.value}
        </label>
      ),
    },
    {
      field: "userEmail",
      headerName: "User Email",
      width: getColumnWidth("userEmail"),
      flex: getColumnFlex("userEmail"),
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary }}>
          {params.value}
        </label>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: getColumnWidth("createdAt"),
      flex: getColumnFlex("createdAt"),
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary }}>
          {params.value}
        </label>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: getColumnWidth("status"),
      flex: getColumnFlex("status"),

      renderCell: (params: GridRenderCellParams) => {
        let statusColor: string;

        switch (params.value.toLowerCase()) {
          case "open":
            statusColor = "#FFA500";
            break;
          case "closed":
            statusColor = "#4CBB17";
            break;
          default:
            statusColor = "inherit";
        }

        return (
          <Chip
            label={params.value}
            sx={{
              color: statusColor,
              backgroundColor: "transparent",
              fontSize: "16px",
              cursor: "default",
              fontWeight: 520,
              border: "none",
            }}
          />
        );
      },
    },
  ];

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Grid
      lg={12}
      sm={12}
      xs={12}
      xl={12}
      md={12}
      sx={{
        backgroundColor: theme.palette.primary.dark,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavBar highlightDashboard={true} />
      <Grid
        container
        sx={{
          backgroundColor: theme.palette.primary.dark,
          paddingX: isLarge ? t.spacing(2) : t.spacing(3),
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <label
          style={{
            color: theme.palette.primary.main,
            fontSize: "34px",
            fontWeight: 700,
            lineHeight: "42px",
          }}
        >
          Support
        </label>
        <Grid
          container
          sx={{
            paddingY: "20px",
            gap: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#1F1F1F",
                lineHeight: "24px",
                textAlign: "center",
                borderRadius: "16px",
                paddingX: "35px",
                paddingY: "10px",
                textTransform: "initial",
                bgcolor: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
              }}
              onClick={() => setCreateTicket(true)}
            >
              Add Ticket
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              padding: "20px",
              borderRadius: "20px",
              backgroundColor: theme.palette.info.light,
              height: "100%",
            }}
          >
            <label
              style={{
                fontSize: "24px",
                fontWeight: 600,
                lineHeight: "32px",
                color: theme.palette.text.primary,
              }}
            >
              Support Tickets
            </label>
            <Grid item xs={12} sx={{ marginTop: "15px" }}>
              {loading ? (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <ProgressLoader strokeColor="#6D1ED4" fillColor="#6D1ED4" />
                </Grid>
              ) : (
                <DataGrid
                  rows={ticketData}
                  columns={columns}
                  autoHeight
                  getRowId={(row) => row.rowId}
                  autoPageSize
                  disableColumnMenu
                  disableColumnResize
                  hideFooterPagination
                  disableColumnSelector
                  hideFooter
                  disableRowSelectionOnClick
                  slots={{
                    columnSortedDescendingIcon: SortedDescendingIcon,
                    columnSortedAscendingIcon: SortedAscendingIcon,
                  }}
                  sx={{
                    height: "100%",
                    width: "100%", // Ensures DataGrid spans full width
                    "& .MuiDataGrid-cell:focus": {
                      outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus": {
                      outline: "none",
                    },
                    "& .MuiDataGrid-row": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      border: "none",
                      backgroundColor: theme.palette.info.light,
                    },
                    "& .MuiDataGrid-columnHeader": {
                      border: "none",
                      backgroundColor: theme.palette.info.light,
                      fontFamily: "Inter",
                      color: theme.palette.info.main,
                      fontWeight: 500,
                      fontSize: "15px",
                      lineHeight: "24px",
                    },
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={createTicket}
        onClose={() => setCreateTicket(false)}
        fullWidth
        maxWidth="sm"
        fullScreen={isSmallScreen}
        PaperProps={{ sx: { borderRadius: isSmallScreen ? "0px" : "20px" } }}
      >
        <Box
          sx={{
            bgcolor: theme.palette.primary.dark,
            textAlign: "center",
            p: 2,
          }}
        >
          <label
            style={{
              color: theme.palette.primary.main,
              fontSize: "19px",
              fontWeight: 700,
            }}
          >
            {" "}
            Create Ticket
          </label>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => setCreateTicket(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{ color: theme.palette.text.primary }} />
        </IconButton>
        <DialogContent sx={{ bgcolor: theme.palette.primary.dark }}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            autoComplete="off"
          >
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              padding={"10px"}
              display={"flex"}
              flexDirection={"column"}
            >
              <FormLabel
                htmlFor="Ticket-Title"
                sx={{
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                }}
              >
                Email
              </FormLabel>
              <TextField
                id="Ticket-Mail"
                size="small"
                name="Mail"
                variant="outlined"
                type="text"
                value={mail}
                placeholder="Enter Email"
                onChange={(e) => {
                  setMail(e.target.value);
                  // validateEmail(e.target.value);
                }}
                // onBlur={() => validateEmail(email)}
                InputProps={{
                  style: {
                    borderRadius: "16px",
                    padding: "3px",
                    marginTop: "5px",
                    fontWeight: 400,
                    fontSize: "14px",
                    border: "1px",
                    color: theme.palette.text.primary,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    // - The Input-root, inside the TextField-root
                    "& fieldset": {
                      // - The <fieldset> inside the Input-root
                      borderColor: theme.palette.text.primary,
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                    },
                    "&.Mui-focused fieldset": {
                      // - Set the Input border when parent is focused
                      borderColor: theme.palette.text.primary,
                    },
                  },
                }}
              />
              {errors.mail && (
                <FormHelperText
                  sx={{ color: theme.palette.error.main, fontWeight: 520 }}
                >
                  {errors.mail}
                </FormHelperText>
              )}
            </Grid>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              padding={"10px"}
              display={"flex"}
              flexDirection={"column"}
            >
              <FormLabel
                htmlFor="Ticket-Title"
                sx={{
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                }}
              >
                Title
              </FormLabel>
              <TextField
                id="Title"
                size="small"
                name="Title"
                variant="outlined"
                type="text"
                value={title}
                placeholder="Title"
                onChange={(e) => {
                  setTitle(e.target.value);
                  //   validateFirstName(e.target.value);
                }}
                // onBlur={() => validateFirstName(firstName)}
                InputProps={{
                  style: {
                    borderRadius: "16px",
                    padding: "3px",
                    marginTop: "5px",
                    fontWeight: 400,
                    fontSize: "14px",
                    border: "1px",
                    color: theme.palette.text.primary,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    // - The Input-root, inside the TextField-root
                    "& fieldset": {
                      // - The <fieldset> inside the Input-root
                      borderColor: theme.palette.text.primary,
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                    },
                    "&.Mui-focused fieldset": {
                      // - Set the Input border when parent is focused
                      borderColor: theme.palette.text.primary,
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              padding={"10px"}
              sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <FormLabel
                htmlFor="Ticket-Categories"
                sx={{
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                }}
              >
                Select Category
              </FormLabel>
              <Select
                style={{
                  color: theme.palette.text.primary,
                  fontFamily: "Inter",
                  padding: "5px 10px",
                  backgroundColor: theme.palette.primary.dark,
                  borderRadius: "16px",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                value={filter}
                label="month"
                onChange={(e) => setFilter(e.target.value)}
                disableUnderline
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent", // Prevents background color change on hover
                  },
                  "& .MuiSelect-select": {
                    "&:focus": {
                      backgroundColor: "transparent", // Ensures the background color doesn't change on focus within the select
                    },
                  },
                  "& .MuiSelect-icon": {
                    color: theme.palette.info.main,
                    display: "none",
                  },

                  fontSize: "14px",
                  lineHeight: "24px",
                  color: theme.palette.info.main,
                  border: `1px solid ${theme.palette.text.primary}`,
                  "&:focus": {
                    border: `1px solid ${theme.palette.primary.main}`, // Changes border color on focus
                  },
                }}
              >
                {filterOptions.map((item) => (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    sx={{
                      borderRadius: "1px",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      lineHeight: "24px",
                      fontWeight: 500,
                      color: theme.palette.info.main,
                      backgroundColor: theme.palette.info.light,
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                          color: "#FFFFFF",
                        },
                      },
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    {item.id}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              padding={"10px"}
              display={"flex"}
              flexDirection={"column"}
            >
              <FormLabel
                htmlFor="Upload-File"
                sx={{
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                }}
              >
                Upload File
              </FormLabel>
              <TextField
                id="Upload-File"
                size="small"
                name="Upload-File"
                variant="outlined"
                type="file"
                onChange={(e) => {
                  const input = e.target as HTMLInputElement;
                  setFile(input.files ? input.files[0] : null); // Check if files exist
                }}
                InputProps={{
                  style: {
                    borderRadius: "16px",
                    padding: "10px",
                    marginTop: "5px",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: theme.palette.text.primary,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.palette.text.primary,
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.text.primary, // - Set border on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.primary.main, // - Set border when focused
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              padding={"10px"}
              display={"flex"}
              flexDirection={"column"}
            >
              <FormLabel
                htmlFor="Ticket-Description"
                sx={{
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                }}
              >
                Description
              </FormLabel>
              <TextField
                id="Description"
                size="small"
                name="Description"
                variant="outlined"
                type="text"
                value={description}
                multiline
                rows={4} // Controls height of textarea
                placeholder="Enter Description"
                onChange={(e) => setDescription(e.target.value)}
                InputProps={{
                  style: {
                    borderRadius: "16px",
                    padding: "10px",
                    marginTop: "5px",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: theme.palette.text.primary,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.palette.text.primary,
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.text.primary, // Changes border on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.primary.main, // Changes border on focus
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              padding={"10px"}
              display={"flex"}
              justifyContent="flex-end" // Align to right
            >
              <Button
                variant="contained"
                size="small" // Make button smaller
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#1F1F1F",
                  borderRadius: "16px",
                  paddingX: "35px",
                  paddingY: "6px", // Reduced padding
                  textTransform: "initial",
                  bgcolor: "#FFFFFF",
                  width: "fit-content", // Constrain width
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                }}
                onClick={handleSubmit}
                disabled={
                  !mail || // Check email
                  !title || // Check title
                  !filter || // Check category
                  !file || // Check file
                  !description || // Check description
                  loading // Check loading state
                }
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default Support;
