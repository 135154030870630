import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  useTheme as T,
  Box,
  Typography,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "../assets/themeContext";
import {
  CancelRounded,
  CheckCircle,
  Error,
  HourglassEmpty,
} from "@mui/icons-material";

interface TermsModalProps {
  show: boolean;
  onHide: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ show, onHide }) => {
  const { theme } = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={show}
      onClose={onHide}
      fullWidth
      maxWidth="sm"
      fullScreen={isSmallScreen}
      PaperProps={{
        sx: {
          borderRadius: isSmallScreen ? "0px" : "20px",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onHide}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon style={{ color: theme.palette.text.primary }} />
      </IconButton>
      <DialogContent sx={{ bgcolor: theme.palette.primary.dark }}>
        <Box sx={{ width: isSmallScreen ? "100%" : "auto", overflowX: "auto" }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            mt={1}
            style={{ color: theme.palette.text.primary, textAlign: "center" }}
          >
            Terms and Conditions
          </Typography>
          <Typography style={{ fontSize: "14px", marginTop:'15px' }}>
            I, hereby give my explicit consent to Cryptoforce India for the
            creation of a crypto account in my name, for the purpose of
            transferring my cryptocurrency, which I am acquiring in Canada via
            PGT, to the Cryptoforce wallet. I confirm that the cryptocurrency
            has been procured using clean, legitimate funds, and I wish to
            transfer it to my wallet that I hold in Cryptoforce India. I also
            authorize Cryptoforce India to facilitate the sale of my
            cryptocurrency in India, on my behalf, and transfer the equivalent
            amount of the proceeds (minus applicable charges and TDS) to my
            designated Indian bank account. I acknowledge and understand the
            terms and conditions associated with the sale, transfer, and any
            deductions, including but not limited to charges and taxes, as
            outlined by Cryptoforce India. Furthermore, I indemnify Cryptoforce
            India and hold it harmless from any legal claims, disputes, or
            litigations that may arise from this transaction, including but not
            limited to those related to the legitimacy, cleanliness, or source
            of the funds used for procuring the cryptocurrency.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TermsModal;
