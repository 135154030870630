const url = process.env.REACT_APP_API_URL;

export const getUserDetails = async () => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(`${url}/user-api/api/user/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch user details:", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};

export const getKycToken = async () => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(`${url}/user-api/api/kyc/access-token`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch user details:", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};

export const updateKycStatus = async (body) => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const raw = JSON.stringify(body);
    const response = await fetch(`${url}/user-api/api/user/`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch user details:", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};

export const updateProfile = async (body) => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const raw = {
      first_name: body.firstName,
      last_name: body.lastName,
      middle_name: body.middleName,
    };
    const response = await fetch(`${url}/user-api/api/user/`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(raw),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch user details:", error);
    throw error;
  }
};

export const deleteUserAccount = async () => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(`${url}/user-api/api/user/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch user details:", error);
    throw error;
  }
};

export const updateUserPassword = async (body) => {
  try {
    const raw = JSON.stringify(body);
    const response = await fetch(`${url}/auth-api/api/auth/update-password`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch user details:", error);
    throw error;
  }
};


export const otpForgotPassword = async (body) => {
  try {
    const raw = JSON.stringify(body);
    const response = await fetch(`${url}/auth-api/api/auth/forgot-password`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch user details:", error);
    throw error;
  }
};
