const token = sessionStorage.getItem("access_token");
const url = process.env.REACT_APP_API_URL;

export const getRecentRecipients = async () => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/recipient-api/api/recipients/recipients/?is_active=true&is_deleted=false&skip=0&limit=4`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch recent recipients:", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};
export const getFrequentRecipient = async () => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/recipient-api/api/recipients/frequent-recipients`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch recent recipients:", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};

export const getTotalRecipients = async (page) => {
  try {
    const pages = (page - 1) * 10;
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/recipient-api/api/recipients/recipients/?is_active=true&is_deleted=false&skip=${pages}&limit=10`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch recent recipients:", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};

export const deleteRecipient = async (recipientId) => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/recipient-api/api/recipients/recipients/${recipientId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("could not delete recipient:", error);
    throw error;
  }
};

export const addRecipient = async (data) => {
  console.log(data);
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/recipient-api/api/recipients/recipients/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Added Content-Type header
        },
        body: JSON.stringify(data), // Moved body to outside headers
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Could not create or add recipient:", error);
    throw error;
  }
};

//// payment methods api's

export const addPayMethod = async (bankdata) => {
  //console.log(data)
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const paymentData = {
      method_type: "bank",
      details: bankdata,
    };
    const response = await fetch(`${url}/payment-methods-api/api/payments/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        // "Content-Type": "application/json", // Added Content-Type header
      },
      body: JSON.stringify(paymentData), // Moved body to outside headers
    });

    if (response.ok) {
      //throw new Error(`HTTP error! status: ${response.status}`);
    

    const responseData = await response.json();
    return responseData;
  }
  } catch (error) {
    console.error("Could not create or add recipient:", error);
    throw error;
  }
};

export const fetchCardApi = async (rawData) => {
  try {
    const paymentData = {
      method_type: "bank",
      details: rawData,
    };

    const response = await fetch(`${url}/payment-methods-api/api/payments/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(paymentData),
    });

    if (response.ok) {
      //throw new Error(`HTTP error! status: ${response.status}`);
    

    const responseData = await response.json();
    return responseData;
  }
  } catch (error) {
    console.error("Error adding card:", error);
    throw error;
  }
};

export const verifyUserBank = async (data) => {
  try {
    const verify = await fetch(`${process.env.REACT_APP_HYPERVERGE_API}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        appKey: process.env.REACT_APP_HYPERVERGE_APP_KEY,
        appId: process.env.REACT_APP_HYPERVERGE_APP_ID,
        transactionId: token,
      },
      body: JSON.stringify(data),
    });
    if (!verify.ok) {
      const errorResponse = await verify.json();
      throw { message: errorResponse.error || "An unexpected error occurred", statusCode: errorResponse.statusCode };
      }
    return verify.json();
  } catch (error) {
    console.error("Error Verifying User Bank Account: ", error);
    throw error;
  }
};