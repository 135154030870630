import React, { useEffect, useState } from "react";
import getExchangeRate from "../services/ExchangeApi";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers/reducers";
import {
  TextField,
  Button,
  Grid,
  Box,
  Avatar,
  IconButton,
  FormHelperText,
  useMediaQuery,
  useTheme as T,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "../assets/themeContext";
import { Dot, ArrowRightLeft } from "lucide-react";
import { ChevronDown, ChevronUp } from "lucide-react";
import images from "../utils/Images";
import RefreshIcon from "../assets/icons/RefreshIcon";
import { toast, ToastContainer } from "react-toastify";
import { makeOrder } from "../services/Transactionapis";
import { useNavigate } from "react-router-dom";
import TermsModal from "./termsModal";

const CurrencyConverter: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const isMedium = useMediaQuery(t.breakpoints.down("lg"));
  const [loading, setLoading] = useState<boolean>(false);
  const [livePrice, setLivePrice] = useState(83.75);
  const [updatedTime, setUpdatedTime] = useState("");
  const [showContent, setShowContent] = useState(false);
  const [inrValue, setInrValue] = useState<string>("");
  const [usdValue, setUsdValue] = useState<string>("");
  const [isChecked, setIsChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [usdError, setUsdError] = useState<string>("");
  const navigate = useNavigate();
  const { recipientName, paymentMethodId, recipientId, inrAmount, usdAmount } = useSelector(
    (state: RootState) => state.chooseRecipient
  );

  const kycStatus = sessionStorage.getItem("kyc_verified");
  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const validateUsdValue = (text: string) => {
    if (!text) {
      setUsdError("Amount is required");
      return false;
    } else if (parseFloat(text) <= 0) {
      setUsdError("Amount should be greater than 0");
      return false;
    } else {
      setUsdError("");
      return true;
    }
  };

  const hasRecipient = recipientName && paymentMethodId && recipientId;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  function convertToCanadianTime(isoTimestamp: string) {
    const date = new Date(isoTimestamp);
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      // second: "2-digit",
      hour12: false,
      timeZone: "America/Toronto",
    };
    const timeString = date.toLocaleString("en-CA", options);
    return `${timeString} EST, Canada`;
  }
  function getTimeDifferenceInMinutes(timestamp: string) {
    const nowUTC = Date.now();
    const updateTimeUTC = new Date(timestamp).getTime();
    const diffMs = nowUTC - updateTimeUTC;
    return Math.floor(diffMs / 60000);
  }

  const fetchLivePrice = async () => {
    try {
      const data = await getExchangeRate();
      const updateRate = data.rate.toFixed(2);
      setLivePrice(parseFloat(updateRate) + 1);
      const canadianTime = convertToCanadianTime(data.timestamp + "Z");
      setUpdatedTime(canadianTime);
      const diffMinutes = getTimeDifferenceInMinutes(data.timestamp + "Z");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchLivePrice();
  }, []);

  const handleClick = () => {
    fetchLivePrice();
  };

  useEffect(() => {
    if(parseFloat(inrAmount) > 0 && parseFloat(usdAmount) > 0){
      setInrValue(inrAmount)
      setUsdValue(usdAmount)
    }else{
      setInrValue("")
      setUsdValue("")
    }
  },[inrAmount, usdAmount])

  const makeTransaction = async () => {
    if (!inrValue || !usdValue) {
      toast.error("Please fill in all fields");
      return;
    }

    if (parseFloat(usdValue) <= 0) {
      toast.error("Amount should be greater than 0");
      return;
    }
    const ValueFlag = inrValue && usdValue && parseFloat(usdValue) > 0;

    if (!ValueFlag) return;

    setLoading(true);
    try {
      const data = {
        payment_method_id: paymentMethodId,
        recipients_id: recipientId,
        usd_transfer_amount: parseFloat(usdValue),
        inr_transfer_amount: parseFloat(inrValue),
        notes: "transfer",
      };

      const response = await makeOrder(data);

      if (response?.apaylo_response?.Result?.ReferenceNumber) {
        const referenceNumber = response.apaylo_response.Result.ReferenceNumber;
        const interacRoute = `${process.env.REACT_APP_INTERAC_PAGE_URL}${referenceNumber}`;
        window.open(interacRoute, "_blank");

        setUsdValue("");
        setInrValue("");
      }
    } catch (error) {
      console.error(error);
      toast.error("Transaction failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // To mask the Number based on ID
  const maskNumber = (paymentMethodId: string) => {
    const isIncludes = paymentMethodId.includes("@");
    if (isIncludes) {
      const visibleDigits = paymentMethodId.slice(-6);
      const maskedPart = paymentMethodId.slice(0, -6).replace(/./g, "X");
      return maskedPart + visibleDigits;
    } else {
      const visibleDigits = paymentMethodId.slice(-4);
      const maskedDigits = "X".repeat(paymentMethodId.length - 4);
      const formattedMaskedDigits = maskedDigits.replace(/(.{4})/g, "$1 ");
      return formattedMaskedDigits + visibleDigits;
    }
  };

  const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUsdValue = e.target.value;
    setUsdValue(newUsdValue);
    if (newUsdValue.trim() === "" || isNaN(Number(newUsdValue))) {
      setInrValue("");
    } else {
      const convertedValue = (parseFloat(newUsdValue) * livePrice).toFixed(2);
      setInrValue(convertedValue);
    }
  };

  return (
    <Grid
      lg={12}
      md={12}
      xs={12}
      xl={12}
      sm={12}
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
        display: "flex",
        flexDirection: "column",
        alignItems: "none",
        height: "100%",
      }}
    >
      <Grid
        lg={12}
        md={12}
        xs={12}
        xl={12}
        sm={12}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"225px"}
        sx={{
          background: "linear-gradient(to bottom , #6D1ED4,#613E8E )",
          borderRadius: "18px",
          paddingX: "40px",
          paddingY: "20px",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            paddingY: "8px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "6px",
              alignItems: "center",
            }}
          >
            <Grid sx={{ borderRadius: "55%" }}>
              <img
                src={images.cadFlag.src}
                alt={images.cadFlag.src}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </Grid>
            <label
              style={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24px",
                color: "#FFFFFF",
                paddingBottom: "5px",
              }}
            >
              CAD
            </label>
          </Grid>
          <Grid sx={{ color: theme.palette.info.main, paddingTop: "6px" }}>
            <ArrowRightLeft size={22} />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "6px",
              alignItems: "center",
            }}
          >
            <Grid sx={{ borderRadius: "60px" }}>
              <img
                src={images.indianFlag.src}
                alt={images.indianFlag.src}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </Grid>
            <label
              style={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24px",
                color: "#FFFFFF",
                paddingBottom: "5px",
              }}
            >
              INR
            </label>
          </Grid>
        </Grid>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          textAlign={"center"}
          alignItems={"center"}
        >
          <label
            style={{
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              padding: "5px",
              color: theme.palette.info.main,
            }}
          >
            1 CAD Equals
          </label>
          <label
            style={{
              fontSize: isMedium ? "30px" : "35px",
              fontWeight: 700,
              lineHeight: isMedium ? "20px" : "24px",
              color: "#FFFFFF",
              padding: "5px",
            }}
          >
            ₹ {livePrice} INR
          </label>
          <Grid
            display={"flex"}
            flexDirection={"row"}
            gap={"12px"}
            alignItems={"center"}
            marginY={"10px"}
          >
            <Box>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#FFFFFF",
                }}
              >
                Updated at {updatedTime}
              </label>
            </Box>
            <IconButton
              sx={{
                cursor: "pointer",
                padding: "5px",
                "&:active": {
                  bgcolor: "rgba(109, 30, 212, 0.3)",
                },
              }}
              onClick={handleClick}
            >
              <RefreshIcon style={{ color: "#FFFFFF" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        lg={11}
        md={11}
        xs={11}
        xl={11}
        sm={11}
        alignSelf={"center"}
        width={"100%"}
      >
        <Grid
          lg={12}
          md={12}
          xs={12}
          xl={12}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingY: "30px",
          }}
        >
          <Grid lg={5.2} md={5.2} xs={5.2} xl={5.2} sm={5.2}>
            <label
              style={{
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18.25px",
                color: theme.palette.info.main,
              }}
            >
              You Send
            </label>
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              onChange={handleValue}
              onBlur={() => validateUsdValue(usdValue)}
              value={usdValue}
              autoComplete="off"
              type="number"
              placeholder="value"
              InputProps={{
                endAdornment: (
                  <label
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "14.52px",
                    }}
                  >
                    CAD
                  </label>
                ),
                style: {
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "24.2px",
                },
              }}
              style={{
                backgroundColor: "#F4F7FE",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    borderColor: "none",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "none",
                  },
                },
                borderRadius: "7px",
              }}
            />
            {usdError && (
              <FormHelperText
                sx={{
                  color: theme.palette.error.main,
                  fontWeight: 520,
                  fontSize: "12px",
                }}
              >
                {usdError}
              </FormHelperText>
            )}
          </Grid>

          <Grid lg={5.2} md={5.2} xs={5.2} xl={5.2} sm={5.2}>
            <label
              style={{
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18.25px",
                color: theme.palette.info.main,
              }}
            >
              They Recieve
            </label>
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              defaultValue={livePrice}
              value={inrValue}
              placeholder="value"
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <label
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "14.52px",
                    }}
                  >
                    INR
                  </label>
                ),
                style: {
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "24.2px",
                },
              }}
              style={{
                backgroundColor: "#F4F7FE",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    borderColor: "none",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "none",
                  },
                },
                borderRadius: "7px",
              }}
            />
          </Grid>
        </Grid>

        <Grid direction="column" alignItems="center" spacing={2}>
          <Grid
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={toggleContent}
          >
            <label
              style={{
                color: theme.palette.text.primary,
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "16.49px",
              }}
            >
              View Breakdown
            </label>
            {showContent ? (
              <ChevronUp
                size={20}
                style={{ color: theme.palette.text.primary, padding: "2px" }}
              />
            ) : (
              <ChevronDown
                size={20}
                style={{ color: theme.palette.text.primary, padding: "2px" }}
              />
            )}
          </Grid>
          {showContent && (
            <Grid display={"flex"} direction="column">
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Dot
                  style={{
                    height: "25px",
                    width: "37px",
                    color: theme.palette.text.primary,
                  }}
                  strokeWidth={3}
                />
                <label
                  style={{
                    fontWeight: 500,
                    fontSize: "13px",
                    color: theme.palette.text.primary,
                    lineHeight: "16px",
                  }}
                >
                  Exchange Rate 1 CAD ={" "}
                  <span style={{ textDecoration: "line-through" }}>
                    {livePrice - 1}
                  </span>{" "}
                  {livePrice}
                </label>
              </Grid>
              <Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Dot
                    style={{
                      height: "25px",
                      width: "37px",
                      color: theme.palette.text.primary,
                    }}
                    strokeWidth={3}
                  />

                  <label
                    style={{
                      fontWeight: 500,
                      fontSize: "13px",
                      color: theme.palette.text.primary,
                      lineHeight: "16px",
                    }}
                  >
                    Processing fee (+1)
                  </label>
                </Grid>
              </Grid>

              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Dot
                  style={{
                    height: "25px",
                    width: "37px",
                    color: theme.palette.text.primary,
                  }}
                  strokeWidth={3}
                />
                <label
                  style={{
                    fontWeight: 500,
                    fontSize: "13px",
                    color: theme.palette.text.primary,
                    lineHeight: "16px",
                  }}
                >
                  Donation towards Girl Child Education: ₹1 = 0.012
                </label>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid lg={12} md={12} xs={12} xl={12} sm={12} marginY={"20px"}>
          <label
            style={{
              flex: 1,
              fontSize: "16px",
              fontWeight: 700,
              color: theme.palette.primary.main,
            }}
          >
            Recipient
          </label>
          {hasRecipient ? (
            <Grid
              sx={{
                bgcolor: theme.palette.info.light,
                borderRadius: "10px",
                boxShadow: theme.palette.action.disabled,
                marginTop: "10px",
                padding: "10px",
              }}
            >
              <Grid
                xl={12}
                lg={12}
                sm={10}
                md={10}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid
                  sx={{
                    gap: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    bgcolor: theme.palette.info.light,
                  }}
                >
                  <Avatar
                    sx={{
                      width: 40,
                      height: 40,
                      bgcolor: "rgba(109, 30, 212, 0.3)",
                      marginLeft: "20px",
                    }}
                  />
                  <Grid
                    sx={{ display: "flex", flexDirection: "column", flex: 1 }}
                  >
                    <Grid>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "16px",
                          color: theme.palette.text.primary,
                        }}
                      >
                        {recipientName}
                      </label>
                    </Grid>
                    <Grid>
                      <label
                        style={{
                          fontSize: "12px",
                          color: theme.palette.info.main,
                        }}
                      >
                        {maskNumber(paymentMethodId)}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  onClick={() => navigate("/recipients")}
                >
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: theme.palette.primary.main,
                      marginLeft: "20px",
                      cursor: "pointer",
                    }}
                  >
                    Choose Another
                  </label>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              sx={{
                paddingY: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  fontWeight: 550,
                  fontSize: "14px",
                  lineHeight: "24px",
                  padding: "8px 16px",
                  borderRadius: "24px",
                  textTransform: "initial",
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                onClick={() => navigate("/recipients")}
              >
                Choose Recipient
              </Button>
            </Grid>
          )}
        </Grid>

        {hasRecipient && (
          <Grid
            xs={12}
            my={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  color="primary"
                  size="small" // Adjusting the checkbox size
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 18, // Customizing checkbox icon size
                    },
                  }}
                />
              }
              label="I accept the terms and conditions"
            />
          </Grid>
        )}
        {hasRecipient && (
          <Grid
            textAlign={"center"}
            sx={{ marginTop: "35px", marginBottom: "20px" }}
          >
            <LoadingButton
              variant="contained"
              disabled={!inrValue || !usdValue || !isChecked}
              loading={loading}
              size="small"
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
                padding: "7px 14px",
                borderRadius: "70px",
                bgcolor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
                "&.Mui-disabled": {
                  backgroundColor: theme.palette.primary.main,
                  color: "#FFFFFF",
                  opacity: 0.5,
                },
              }}
              onClick={() =>
                kycStatus === "true" ? makeTransaction() : navigate("/kyc")
              }
            >
              Transfer Now
            </LoadingButton>
          </Grid>
        )}
      </Grid>
      <ToastContainer />
      <TermsModal show={openModal} onHide={() => setOpenModal(false)} />
    </Grid>
  );
};

export default CurrencyConverter;
