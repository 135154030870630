import React, { useEffect, useState } from "react";
import { Grid, Divider, IconButton } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import {
  deleteNotification,
  getNotifications,
} from "../services/Notificationapis";
import ProgressLoader from "./Loader";
import { Trash2 } from "lucide-react";
import { toast } from "react-toastify";

interface MappedNotification {
  notificationId: string;
  title: string;
  message: string;
}

interface Notification {
  _id: string;
  title: string;
  message: string;
}

const NotificationModal: React.FC = () => {
  const { theme } = useTheme();
  const [notificationData, setNotificationData] = useState<
    MappedNotification[]
  >([]);
  const [loading, setLoading] = useState(false);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const result: Notification[] = await getNotifications();
      // console.log(result);
      const mappedData: MappedNotification[] = result.map(
        (item: Notification) => ({
          notificationId: item._id,
          title: item.title,
          message: item.message,
        })
      );

      // console.log(mappedData);
      setNotificationData(mappedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (notificationId: string) => {
    try {
      await deleteNotification(notificationId);
      toast.success("successfully deleted");
    } catch (error) {
      console.log(error);
    }

    fetchNotifications();
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Grid >
      {loading ? (
        <Grid
          width={"100%"}
          height={"100%"}
          lg={12}
          sm={12}
          xs={12}
          xl={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <ProgressLoader strokeColor="#6D1ED4" fillColor="#6D1ED4" />
        </Grid>
      ) : (
        <Grid>
          {notificationData.length > 0 ? (
             notificationData.map((item) => (
              <>
                <Grid
                  key={item.notificationId}
                  sx={{
                    padding: "18px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid sx={{ display: "flex", flexDirection: "column" }}>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "16px",
                        color: theme.palette.text.primary,
                        padding: "5px 0px",
                      }}
                    >
                      {item.title}
                    </label>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 450,
                        lineHeight: "16px",
                        color: theme.palette.info.main,
                        padding: "5px 0px",
                      }}
                    >
                      {item.message}
                    </label>
                  </Grid>
                  <IconButton
                    sx={{
                      "&.hover": {
                        backgroundColor: "#F4F6F9",
                      },
                      "&:active": {
                        bgcolor:'rgba(109, 30, 212, 0.3)', // Change the background color here
                      },
                    }}
                  >
                    <Trash2
                      style={{
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete(item.notificationId)}
                    />
                  </IconButton>
                </Grid>
                <Divider
                  sx={{
                    borderColor: theme.palette.divider,
                    border: "1px light",
                    marginTop: "2px",
                  }}
                />
              </>
            ))
          ):(
            <Grid
            width={"100%"}
            height={"500px"}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <label
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "16px",
                color: theme.palette.text.primary,
                padding: "5px 0px",
              }}
            >
              No Notifications
            </label>
          </Grid>
          )}
         
        </Grid>
      )}
    </Grid>
  );
};

export default NotificationModal;
