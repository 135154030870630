import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  useTheme as T,
  Box,
  Typography,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "../assets/themeContext";
import { CreditCard, QrCode } from "lucide-react";

interface MappedRecipient {
  rowId: any;
  recipientID: string;
  name: string;
  mobileNumber: string;
  paymentMethods: string;
  paymentThrough: string;
  email: string;
  Date: string;
}

interface RecipientDetailModalProps {
  show: boolean;
  onHide: () => void;
  RecipientDetails: MappedRecipient | null;
}

const RecipientDetailModal: React.FC<RecipientDetailModalProps> = ({
  show,
  onHide,
  RecipientDetails,
}) => {
  const { theme } = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={show}
      onClose={onHide}
      fullWidth
      maxWidth="sm"
      fullScreen={isSmallScreen}
      PaperProps={{
        sx: {
          borderRadius: isSmallScreen ? "0px" : "20px",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onHide}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon style={{ color: theme.palette.text.primary }} />
      </IconButton>
      <DialogContent sx={{ bgcolor: theme.palette.primary.dark }}>
        <Box sx={{ width: isSmallScreen ? "100%" : "auto", overflowX: "auto" }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            mt={1}
            style={{ color: theme.palette.text.primary, textAlign: "center" }}
          >
            Recipient Details
          </Typography>
          <Grid
            xs={12}
            mt={4}
            mb={2}
            sx={{ display: "flex", flexDirection: "column", gap: "2px" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              mb={0.5}
              fontSize={"1.5rem"}
              style={{
                color: theme.palette.text.primary,
              }}
            >
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 15 : 18, fontWeight: 500 }}
              >
                Recipient Id
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 13 : 17 }}
              >
                {RecipientDetails?.recipientID}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              mb={0.5}
              fontSize={"1.5rem"}
              style={{
                color: theme.palette.text.primary,
              }}
            >
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 15 : 18, fontWeight: 500 }}
              >
                Recipient Name
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 13 : 17 }}
              >
                {RecipientDetails?.name || "N/A"}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              mb={0.5}
              fontSize={"1.5rem"}
              style={{
                color: theme.palette.text.primary,
              }}
            >
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 15 : 18, fontWeight: 500 }}
              >
                Email
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 13 : 17 }}
              >
                {RecipientDetails?.email || "N/A"}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              mb={0.5}
              fontSize={"1.5rem"}
              style={{
                color: theme.palette.text.primary,
              }}
            >
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 15 : 18, fontWeight: 500 }}
              >
                Mobile Number
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 13 : 17 }}
              >
                {RecipientDetails?.mobileNumber || "N/A"}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              mb={0.5}
              fontSize={"1.5rem"}
              style={{
                color: theme.palette.text.primary,
              }}
            >
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 15 : 18, fontWeight: 500 }}
              >
                Paymemt Method
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 13 : 17, display:'flex', flexDirection:'row', alignItems:'center', gap:'5px' }}
              >
                {RecipientDetails?.paymentMethods.includes("@") ? (
                  <QrCode
                    style={{
                      color: theme.palette.primary.main,
                      outline: "none",
                      height: 23,
                      width: 23,
                    }}
                  />
                ) : (
                  <CreditCard
                    style={{
                      color: theme.palette.primary.main,
                      outline: "none",
                      height: 23,
                      width: 23,
                    }}
                  />
                )}
                {RecipientDetails?.paymentMethods || "N/A"}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              mb={0.5}
              fontSize={"1.5rem"}
              style={{
                color: theme.palette.text.primary,
              }}
            >
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 15 : 18, fontWeight: 500 }}
              >
                Created Date
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: isSmallScreen ? 13 : 17 }}
              >
                {RecipientDetails?.Date || "N/A"}
              </Typography>
            </Box>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RecipientDetailModal;
