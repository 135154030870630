// src/context/ThemeContext.tsx
import React, { createContext, useState, useContext, useCallback, ReactNode, useEffect } from 'react';
import lightTheme from './light-theme/index';
import darkTheme from './dark-theme/index';
interface ThemeContextProps {
  theme: any; // Change 'any' to your theme type if you have one
  toggleTheme: () => void;
  isDarkMode: boolean;
}

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {

   const storedTheme = sessionStorage.getItem('isDarkMode');
   const initialTheme = storedTheme ? JSON.parse(storedTheme) : false;
 
   const [isDarkMode, setIsDarkMode] = useState<boolean>(initialTheme);
 
   useEffect(() => {
     sessionStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
   }, [isDarkMode]);
 
   const theme = isDarkMode ? darkTheme : lightTheme;
 
   const toggleTheme = () => {
     setIsDarkMode((prev) => !prev);
   };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, isDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
export default ThemeContext;