import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  FormHelperText,
  FormLabel,
  useTheme as T,
  useMediaQuery,
  Dialog,
  DialogContent,
  IconButton,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "../assets/themeContext";
import AuthBackground from "../components/AuthBackground";
import { Login } from "../services/Authapis";
import { toast, ToastContainer } from "react-toastify";
import { persistor } from "../redux/store";
import {
  getUserDetails,
  otpForgotPassword,
  updateUserPassword,
} from "../services/Profileapis";
import CloseIcon from "@mui/icons-material/Close";
import { Eye, EyeOff } from "lucide-react";
interface Errors {
  mail?: string | null;
  password?: string | null;
  verifymail?: string | null;
  confirm?: string | null;
  new?: string | null;
  otp?: string | null;
}

const LoginForm: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const is900Screen = useMediaQuery(t.breakpoints.down(900));
  const isSmallScreen = useMediaQuery(t.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState("");
  const [forgotModal, setForgotModal] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpCode, setOtpCode] = useState<string>("");

  useEffect(() => {
    document.title = "AptRemit-Login";
    persistor.purge();
  });

  const validateEmail = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, mail: "Email is required" }));
      return false;
    } else {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      if (!emailPattern.test(text)) {
        setErrors((prev) => ({ ...prev, mail: "Invalid email format" }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, mail: null }));
        return true;
      }
    }
  };
  const validateVerifyEmail = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, verifymail: "Email is required" }));
      return false;
    } else {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      if (!emailPattern.test(text)) {
        setErrors((prev) => ({ ...prev, verifymail: "Invalid email format" }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, verifymail: null }));
        return true;
      }
    }
  };

  const validatePassword = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, password: "Password is required" }));
      return false;
    } else {
      if (text.length < 8) {
        setErrors((prev) => ({
          ...prev,
          password: "Password must be at least 8 characters long",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, password: null }));
        return true;
      }
    }
  };

  const validateNew = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, new: "New Password is required" }));
      return false;
    } else {
      if (text.length < 8) {
        setErrors((prev) => ({
          ...prev,
          new: "Password must be at least 8 characters long",
        }));
        return false;
      }
      setErrors((prev) => ({ ...prev, new: null }));
      return true;
    }
  };

  const validateOtp = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, otp: "otp is required" }));
      return false;
    } else {
      if (text.length < 6) {
        setErrors((prev) => ({
          ...prev,
          otp: "otp must be 6 characters long",
        }));
        return false;
      }
      setErrors((prev) => ({ ...prev, otp: null }));
      return true;
    }
  };

  const validateConfirm = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, confirm: "Password is required" }));
      return false;
    } else {
      if (text.length < 8) {
        setErrors((prev) => ({
          ...prev,
          confirm: "Password must be at least 8 characters long",
        }));
        return false;
      } else if (text !== newPassword) {
        setErrors((prev) => ({
          ...prev,
          confirm: "Confirm password must be same as new password",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, confirm: null }));
        return true;
      }
    }
  };

  function handleTogglePasswordVisibility() {
    setShowPassword(!showPassword);
  }
  const getUserProfileDetails = async () => {
    try {
      const data = await getUserDetails();
      let kyc;
      if (data?.kyc_verified === null) {
        kyc = false;
      } else {
        kyc = data?.kyc_verified;
      }
      sessionStorage.setItem("kyc_verified", kyc.toString());
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    persistor.purge();
    if (!email || !password) {
      toast.error("Fill the Fields");
      return;
    }
    try {
      const response = await Login({ username: email, password: password });
      console.log("Response:", response);

      if (response.success) {
        sessionStorage.setItem("access_token", response.access_token);
        sessionStorage.setItem("refresh_token", response.refresh_token);
        await getUserProfileDetails();
        setTimeout(() => {
          navigate("/home");
        }, 1000);
        toast.success("Login Successfull", { autoClose: 2000 });
        // console.log('token access:', response.access_token);
        // console.log('token refresh:', response.refresh_token);

        console.error("message:", response.message);
      } else {
        console.error("message:", response.message);
        toast.error(response.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleForgotOtp = async () => {
    try {
      const forgotEmail = {
        email: verifyEmail,
      };
      const response = await otpForgotPassword(forgotEmail);
      if (response?.message) {
        toast.success(response?.message, { autoClose: 2000 });
        setSendEmail(false);
        setForgotModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdatePassword = async () => {
    try {
      const update = {
        username: verifyEmail,
        password: newPassword,
        otp: otpCode,
      };
      const response = await updateUserPassword(update);
      if (response?.detail) {
        toast.success(response?.detail, { autoClose: 2000 });
        setConfirmPassword("");
        setNewPassword("");
        setOtpCode("");
        setForgotModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid
        container
        lg={12}
        sm={12}
        md={12}
        display={"flex"}
        flexDirection={"row"}
        sx={{ justifyContent: is900Screen ? "center" : null }}
      >
        <Grid
          lg={6}
          md={8}
          sm={12}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          height={is900Screen ? "100vh" : "null"}
        >
          <Grid
            lg={7}
            md={10}
            sm={12}
            width={isSmallScreen ? "90%" : "60%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Grid lg={12} display={"flex"} flexDirection={"column"}>
              <label
                style={{
                  fontSize: "36px",
                  fontWeight: 700,
                  lineHeight: "56px",
                  color: theme.palette.secondary.main,
                  paddingBottom: "10px",
                }}
              >
                Sign In
              </label>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "rgba(156, 174, 184, 1)",
                }}
              >
                Enter your email and password to sign in!
              </label>
            </Grid>

            <Grid lg={12} marginY={"25px"}>
              {/* Input Elements */}
              <form onSubmit={handleLogin}>
                <Grid marginBottom={"20px"}>
                  <FormLabel
                    htmlFor="Email"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: theme.palette.secondary.main,
                    }}
                  >
                    Email
                    <span
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "16px",
                        lineHeight: "16px",
                        fontWeight: 500,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <TextField
                    fullWidth
                    id="Email"
                    size="small"
                    variant="outlined"
                    name="email"
                    value={email}
                    type="email"
                    placeholder="mail@simmmple.com"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      validateEmail(e.target.value);
                    }}
                    onBlur={() => validateEmail(email)}
                    InputProps={{
                      style: {
                        borderRadius: "16px",
                        padding: "3px",
                        marginTop: "5px",
                        fontWeight: 400,
                        fontSize: "14px",
                        border: "1px  rgba(156, 174, 184, 0.5)",
                      },
                    }}
                  />
                  {errors.mail && (
                    <FormHelperText
                      sx={{ color: theme.palette.error.main, fontWeight: 520 }}
                    >
                      {errors.mail}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid>
                  <FormLabel
                    htmlFor="Password"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: theme.palette.secondary.main,
                    }}
                  >
                    Password
                    <span
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "14px",
                        lineHeight: "14px",
                        fontWeight: 500,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <TextField
                    fullWidth
                    id="Password"
                    size="small"
                    variant="outlined"
                    name="password"
                    value={password}
                    type={showPassword ? "text" : "password"}
                    placeholder="Min. 8 characters"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      validatePassword(e.target.value);
                    }}
                    onBlur={() => validatePassword(password)}
                    InputProps={{
                      style: {
                        borderRadius: "16px",
                        padding: "3px",
                        marginTop: "5px",
                        fontWeight: 400,
                        fontSize: "14px",
                        border: "1px rgba(156, 174, 184, 0.5)",
                      },
                      endAdornment: (
                        <Button onClick={handleTogglePasswordVisibility}>
                          {showPassword ? (
                            <Visibility sx={{ color: "#9CAEB8" }} />
                          ) : (
                            <VisibilityOff sx={{ color: "#9CAEB8" }} />
                          )}
                        </Button>
                      ),
                    }}
                  />
                  <FormHelperText
                    sx={{ color: theme.palette.error.main, fontWeight: 520 }}
                  >
                    {errors.password}
                  </FormHelperText>
                </Grid>

                <Grid
                  lg={12}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  pt={"15px"}
                >
                  <Grid
                    lg={12}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: theme.palette.primary.main,
                        lineHeight: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setSendEmail(true)}
                    >
                      Forget password?
                    </label>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  disabled={!email || !password}
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: theme.palette.text.secondary,
                    lineHeight: "16px",
                    textAlign: "center",
                    width: "100%",
                    borderRadius: "16px",
                    paddingX: "8px",
                    paddingY: "15px",
                    marginY: "20px",
                    bgcolor: theme.palette.primary.main,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                  onClick={handleLogin}
                  type="submit"
                >
                  Sign In
                </Button>
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "26px",
                    color: theme.palette.secondary.main,
                  }}
                >
                  Not registered yet?
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/SignUp")}
                  >
                    {" "}
                    Create an Account
                  </span>
                </label>
              </form>
              <Grid lg={12} textAlign={"center"} mt={"40px"}>
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                  }}
                >
                  © 2024 AptRemit. All Rights Reserved.
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {is900Screen ? null : (
          <Grid lg={6} md={4}>
            <AuthBackground />
          </Grid>
        )}
        <ToastContainer />
      </Grid>
      <Dialog
        open={sendEmail}
        onClose={() => setSendEmail(false)}
        fullWidth
        maxWidth="xs"
        PaperProps={{ sx: { borderRadius: "20px" } }}
      >
        <Box
          sx={{
            bgcolor: theme.palette.primary.dark,
            textAlign: "center",
            p: 2,
          }}
        >
          <label
            style={{
              color: theme.palette.primary.main,
              fontSize: "19px",
              fontWeight: 700,
            }}
          >
            {" "}
            Verify Email
          </label>
        </Box>
        <DialogContent
          sx={{ bgcolor: theme.palette.primary.dark, px: 2, py: 1 }}
        >
          <Grid marginBottom={"20px"}>
            <FormLabel
              htmlFor="Verify-Email"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.secondary.main,
              }}
            >
              Email
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "16px",
                  lineHeight: "16px",
                  fontWeight: 500,
                }}
              >
                *
              </span>
            </FormLabel>
            <TextField
              fullWidth
              id="Verify-Email"
              size="small"
              variant="outlined"
              name="email"
              value={verifyEmail}
              type="email"
              placeholder="mail@simmmple.com"
              onChange={(e) => {
                setVerifyEmail(e.target.value);
                validateVerifyEmail(e.target.value);
              }}
              onBlur={() => validateVerifyEmail(verifyEmail)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px  rgba(156, 174, 184, 0.5)",
                },
              }}
            />
            {errors.verifymail && (
              <FormHelperText
                sx={{ color: theme.palette.error.main, fontWeight: 520 }}
              >
                {errors.verifymail}
              </FormHelperText>
            )}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#1F1F1F",
                lineHeight: "24px",
                textAlign: "center",
                borderRadius: "16px",
                paddingX: "35px",
                paddingY: "10px",
                textTransform: "initial",
                bgcolor: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
              }}
              onClick={() => setSendEmail(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!verifyEmail}
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#FFFFFF",
                lineHeight: "24px",
                textAlign: "center",
                borderRadius: "16px",
                paddingX: "35px",
                paddingY: "10px",
                textTransform: "initial",
                bgcolor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
              onClick={handleForgotOtp}
            >
              Verify
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={forgotModal}
        onClose={() => setForgotModal(false)}
        fullWidth
        maxWidth="xs"
        PaperProps={{ sx: { borderRadius: "20px" } }}
      >
        <Box
          sx={{
            bgcolor: theme.palette.primary.dark,
            textAlign: "center",
            p: 2,
          }}
        >
          <label
            style={{
              color: theme.palette.primary.main,
              fontSize: "19px",
              fontWeight: 700,
            }}
          >
            {" "}
            Reset Password
          </label>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => setForgotModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{ color: theme.palette.text.primary }} />
        </IconButton>
        <DialogContent
          sx={{ bgcolor: theme.palette.primary.dark, px: 2, py: 1 }}
        >
          <Grid lg={12} md={12} sm={12} xs={12} xl={12} marginTop={"20px"}>
            <FormLabel
              htmlFor="new"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              New Password
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: 500,
                }}
              >
                *
              </span>
            </FormLabel>
            <TextField
              fullWidth
              id="new"
              size="small"
              variant="outlined"
              name="new"
              value={newPassword}
              type={showNew ? "text" : "password"}
              placeholder="Min. 8 characters"
              onChange={(e) => {
                setNewPassword(e.target.value);
                validateNew(e.target.value);
              }}
              onBlur={() => validateNew(newPassword)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
                endAdornment: (
                  <Button onClick={() => setShowNew(!showNew)}>
                    {showNew ? (
                      <Eye style={{ color: "#9CAEB8" }} />
                    ) : (
                      <EyeOff style={{ color: "#9CAEB8" }} />
                    )}
                  </Button>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            <FormHelperText
              sx={{ color: theme.palette.error.main, fontWeight: 520 }}
            >
              {errors.new}
            </FormHelperText>
          </Grid>
          <Grid lg={12} md={12} sm={12} xs={12} xl={12} marginTop={"20px"}>
            <FormLabel
              htmlFor="confirm"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Confirm Password
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: 500,
                }}
              >
                *
              </span>
            </FormLabel>
            <TextField
              fullWidth
              id="confirm"
              size="small"
              variant="outlined"
              name="confirm"
              value={confirmPassword}
              type={showConfirm ? "text" : "password"}
              placeholder="Min. 8 characters"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                validateConfirm(e.target.value);
              }}
              onBlur={() => validateConfirm(confirmPassword)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
                endAdornment: (
                  <Button onClick={() => setShowConfirm(!showConfirm)}>
                    {showConfirm ? (
                      <Eye style={{ color: "#9CAEB8" }} />
                    ) : (
                      <EyeOff style={{ color: "#9CAEB8" }} />
                    )}
                  </Button>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            <FormHelperText
              sx={{ color: theme.palette.error.main, fontWeight: 520 }}
            >
              {errors.confirm}
            </FormHelperText>
          </Grid>
          <Grid marginBottom={"20px"}>
            <FormLabel
              htmlFor="Verify-otp"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.secondary.main,
              }}
            >
              OTP
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "16px",
                  lineHeight: "16px",
                  fontWeight: 500,
                }}
              >
                *
              </span>
            </FormLabel>
            <TextField
              fullWidth
              id="Verify-otp"
              size="small"
              variant="outlined"
              name="otp"
              value={otpCode}
              type="number"
              onChange={(e) => {
                setOtpCode(e.target.value);
                validateOtp(e.target.value);
              }}
              onBlur={() => validateOtp(otpCode)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px  rgba(156, 174, 184, 0.5)",
                },
              }}
            />
            {errors.otp && (
              <FormHelperText
                sx={{ color: theme.palette.error.main, fontWeight: 520 }}
              >
                {errors.otp}
              </FormHelperText>
            )}
          </Grid>
          <Grid sx={{ textAlign: "left" }}>
            <label
              style={{
                fontSize: "15px",
                color: theme.palette.primary.main,
                fontFamily: "Inter",
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={handleForgotOtp}
            >
              Resend
            </label>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Button
              variant="contained"
              disabled={!newPassword || !confirmPassword || !otpCode}
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#FFFFFF",
                lineHeight: "24px",
                textAlign: "center",
                borderRadius: "16px",
                paddingX: "35px",
                paddingY: "10px",
                textTransform: "initial",
                bgcolor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
              onClick={handleUpdatePassword}
            >
              Submit
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginForm;
