import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Box,
  Button,
  Avatar,
  useTheme as T,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import images from "../utils/Images";
import { useTheme } from "../assets/themeContext";
import "../utils/Fonts.css";
import getExchangeRate from "../services/ExchangeApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/reducers";
import { selectCard, deslectCard } from "../redux/slices/chooseRecSlice";
import { Dot } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { ChevronDown, ChevronUp } from "lucide-react";
import { getRecentRecipients } from "../services/RecipientApis";
import { toast, ToastContainer } from "react-toastify";
import { makeOrder } from "../services/Transactionapis";
import TermsModal from "../components/termsModal";

type CurrencyFieldProps = {
  label: string;
  value: string | number;
  startAdornmentImgSrc: string;
  endAdornmentText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  sx?: object;
};

interface MappedRecipient {
  rowId: any;
  recipientID: string;
  name: string;
  mobileNumber: string;
  paymentMethods: string;
  paymentThrough: string;
}

interface Recipient {
  _id: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  account_number: string;
  is_upi: boolean;
  upi_id: string;
}

interface SelectedRecipient {
  _id: string;
  mobile_number: string;
  account_number: string;
  payment: string;
  name: string;
}

const CurrencyField: React.FC<CurrencyFieldProps> = ({
  label,
  value,
  onChange,
  startAdornmentImgSrc,
  endAdornmentText,
  readOnly = false,
}) => (
  <TextField
    label=""
    fullWidth
    variant="outlined"
    value={value}
    onChange={onChange}
    InputProps={{
      startAdornment: (
        <img
          src={startAdornmentImgSrc}
          alt={label}
          style={{ width: "40px", paddingRight: "10px" }}
        />
      ),
      endAdornment: <label className="inter500">{endAdornmentText}</label>,
      style: { borderRadius: "12px" },
    }}
    disabled={readOnly}
  />
);

const Conversion: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [usdAmount, setUsdAmount] = useState<string>("");
  const [inrAmount, setInrAmount] = useState<string>("");
  const [conversionRate, setConversionRate] = useState(0);
  const [recipientData, setRecipientData] = useState<MappedRecipient[]>([]);
  const [usdError, setUsdError] = useState<string>("");
  const [selectionError, setSelectionError] = useState<string>("");
  const [ourconversionRate, setourConversionRate] = useState<number | null>(
    null
  );
  const [showContent, setShowContent] = useState(false);
  const [transactionLoad, setTransactionLoad] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [
    selectedRecipient,
    setSelectedRecipient,
  ] = useState<SelectedRecipient | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { recipientName, paymentMethodId, recipientId } = useSelector(
    (state: RootState) => state.chooseRecipient
  );
  const { theme } = useTheme();
  const kycStatus = sessionStorage.getItem("kyc_verified");

  useEffect(() => {
    const fetchExchangeRate = async () => {
      setLoading(true);
      try {
        const data = await getExchangeRate();
        setConversionRate(data.rate);
      } catch (err) {
        setError("Failed to fetch exchange rate");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchExchangeRate();
  }, []);

  useEffect(() => {
    if (conversionRate) {
      setourConversionRate(conversionRate + 1);
    }
  }, [conversionRate]);

  const maskNumber = (payment: string) => {
    const isIncludes = payment.includes("@");
    if (isIncludes) {
      const visibleDigits = payment.slice(-6);
      const maskedPart = payment.slice(0, -6).replace(/./g, "X");
      return maskedPart + visibleDigits;
    } else {
      const visibleDigits = payment.slice(-4);
      const maskedDigits = "X".repeat(payment.length - 4);
      const formattedMaskedDigits = maskedDigits.replace(/(.{4})/g, "$1 ");
      return formattedMaskedDigits + visibleDigits;
    }
  };

  const getAllRecipients = async () => {
    try {
      const response = await getRecentRecipients();
      const mappedData: MappedRecipient[] = response.data.map(
        (recipient: Recipient, index: number) => {
          let paymentMethods: string;
          if (recipient.is_upi) {
            paymentMethods = recipient.upi_id;
          } else {
            paymentMethods = recipient.account_number;
          }
          return {
            rowId: index,
            recipientID: recipient._id,
            name: recipient.first_name + " " + recipient.last_name,
            mobileNumber: recipient.mobile_number,
            paymentMethods: paymentMethods,
            paymentThrough: recipient.is_upi
              ? recipient.upi_id
              : recipient.account_number,
          };
        }
      );

      setRecipientData(mappedData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllRecipients();
  }, []);

  // const validateUsdValue = (text: string) => {
  //   if (!text) {
  //     setUsdError("Amount is required");
  //     return false;
  //   } else if (parseFloat(text) <= 0) {
  //     setUsdError("Amount should be greater than 0");
  //     return false;
  //   } else {
  //     setUsdError("");
  //     return true;
  //   }
  // };

  const handleRecipientChange = (event: SelectChangeEvent<string>) => {
    const selectedRecipientID = event.target.value;
    const selectedRecipientObj = recipientData.find(
      (recipient) => recipient.recipientID === selectedRecipientID
    );

    if (selectedRecipientObj) {
      setSelectedRecipient({
        _id: selectedRecipientObj.recipientID,
        name: selectedRecipientObj?.name,
        mobile_number: selectedRecipientObj.mobileNumber,
        account_number: selectedRecipientObj.paymentMethods,
        payment: selectedRecipientObj?.paymentMethods,
      });
    } else {
      setSelectedRecipient(null);
    }

    const userName = selectedRecipientObj?.name;
    const paymentMethod = selectedRecipientObj?.paymentThrough;
    const recipientID = selectedRecipientObj?.recipientID;

    if (
      userName === recipientName &&
      paymentMethod === paymentMethodId &&
      recipientID === recipientId
    ) {
      dispatch(deslectCard());
    } else {
      dispatch(
        selectCard({
          inrAmount: "",
          usdAmount: "",
          recipientName: userName || "",
          paymentMethodId: paymentMethod || "",
          recipientId: recipientID || "",
        })
      );
    }

    setSelectionError("");
  };

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  // Handle checkbox change
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const handleUsdInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const usdValue = event.target.value;
    setUsdAmount(usdValue);
    if (conversionRate !== null && ourconversionRate !== null) {
      const inrValue = parseFloat(usdValue) * ourconversionRate;
      setInrAmount(isNaN(inrValue) ? "" : inrValue.toFixed(2));
    }
  };

  const handleInrInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inrValue = event.target.value;
    setInrAmount(inrValue);
    if (conversionRate !== null && ourconversionRate !== null) {
      const usdValue = parseFloat(inrValue) / ourconversionRate;
      setUsdAmount(isNaN(usdValue) ? "" : usdValue.toFixed(2));
    }
  };

  const makeTransferInterac = async () => {
    if (!selectedRecipient) {
      setSelectionError("Please select a recipient.");
      return;
    }

    if (!inrAmount || !usdAmount) {
      toast.error("Please fill in all fields");
      return;
    }

    if (parseFloat(usdAmount) <= 0) {
      toast.error("Amount should be greater than 0");
      return;
    }
    const ValueFlag = inrAmount && usdAmount && parseFloat(usdAmount) > 0;

    if (!ValueFlag) return;

    setTransactionLoad(true);
    try {
      const data = {
        payment_method_id: selectedRecipient?.payment,
        recipients_id: selectedRecipient?._id,
        usd_transfer_amount: parseFloat(usdAmount),
        inr_transfer_amount: parseFloat(inrAmount),
        notes: "transfer",
      };

      const response = await makeOrder(data);

      if (response?.apaylo_response?.Result?.ReferenceNumber) {
        const referenceNumber = response.apaylo_response.Result.ReferenceNumber;
        const interacRoute = `${process.env.REACT_APP_INTERAC_PAGE_URL}${referenceNumber}`;
        window.open(interacRoute, "_blank");

        setUsdAmount("");
        setInrAmount("");
      }
    } catch (error) {
      console.error(error);
      toast.error("Transaction failed. Please try again.");
    } finally {
      setTransactionLoad(false);
    }
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Card
        sx={{
          width: { xs: "100%" },
          backgroundColor: theme.palette.info.light,
          borderRadius: 5,
        }}
      >
        <CardContent>
          <label
            style={{
              fontSize: "17.5px",
              fontWeight: "600",
              color: theme.palette.primary.main,
            }}
          >
            Conversion
          </label>
          <Box
            sx={{
              boxShadow: "0px 11.615px 40px rgba(7, 13, 27, 0.05)",
              padding: 2,
              borderRadius: 5,
              marginTop: "10px",
            }}
          >
            <Grid mb={1}>
              <label
                style={{
                  fontSize: "14.5px",
                  fontWeight: "500",
                  color: theme.palette.info.main,
                  opacity: 0.75,
                }}
              >
                You Send
              </label>
            </Grid>
            <CurrencyField
              label="CAD"
              value={usdAmount}
              onChange={handleUsdInputChange}
              startAdornmentImgSrc={images.cadFlag.src}
              endAdornmentText="CAD"
            />
          </Box>
          <Box
            sx={{
              boxShadow: "0px 11.615px 40px rgba(7, 13, 27, 0.05)",
              padding: 2,
              borderRadius: 5,
            }}
          >
            <Grid mb={1}>
              <label
                style={{
                  fontSize: "14.5px",
                  fontWeight: "500",
                  color: theme.palette.info.main,
                  opacity: 0.75,
                }}
              >
                They Receive
              </label>
            </Grid>
            <CurrencyField
              label="INR"
              value={inrAmount}
              onChange={handleInrInputChange}
              startAdornmentImgSrc={images.indianFlag.src}
              endAdornmentText="INR"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    borderColor: "none",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "none",
                  },
                },
                borderRadius: "12px",
              }}
            />
          </Box>
          {loading ? (
            <Box textAlign={"center"}>
              <label style={{ marginTop: 2 }}>Loading...</label>
            </Box>
          ) : error ? (
            <label style={{ marginTop: 2, color: "error.main" }}>{error}</label>
          ) : (
            <Grid
              direction="column"
              alignItems="center"
              spacing={2}
              mt={2}
              px={1}
            >
              <Grid
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={toggleContent}
              >
                <label
                  style={{
                    color: theme.palette.text.primary,
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "16.49px",
                  }}
                >
                  View Breakdown
                </label>
                {showContent ? (
                  <ChevronUp
                    size={20}
                    style={{
                      color: theme.palette.text.primary,
                      padding: "2px",
                    }}
                  />
                ) : (
                  <ChevronDown
                    size={20}
                    style={{
                      color: theme.palette.text.primary,
                      padding: "2px",
                    }}
                  />
                )}
              </Grid>
              {showContent && (
                <Grid display={"flex"} direction="column">
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Dot
                      style={{
                        height: "25px",
                        width: "37px",
                        color: theme.palette.text.primary,
                      }}
                      strokeWidth={3}
                    />
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: "13px",
                        color: theme.palette.text.primary,
                        lineHeight: "16px",
                      }}
                    >
                      Exchange Rate 1 CAD ={" "}
                      <span style={{ textDecoration: "line-through" }}>
                        {conversionRate - 1}
                      </span>{" "}
                      {conversionRate}
                    </label>
                  </Grid>
                  <Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Dot
                        style={{
                          height: "25px",
                          width: "37px",
                          color: theme.palette.text.primary,
                        }}
                        strokeWidth={3}
                      />

                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: "13px",
                          color: theme.palette.text.primary,
                          lineHeight: "16px",
                        }}
                      >
                        Processing fee (+1)
                      </label>
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Dot
                      style={{
                        height: "25px",
                        width: "37px",
                        color: theme.palette.text.primary,
                      }}
                      strokeWidth={3}
                    />
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: "13px",
                        color: theme.palette.text.primary,
                        lineHeight: "16px",
                      }}
                    >
                      Donation towards Girl Child Education: ₹1 = 0.012
                    </label>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {recipientData?.length > 0 && (
            <>
              <Grid item xs={12} mt={3} px={1}>
                <FormControl fullWidth>
                  <InputLabel>Recipient</InputLabel>
                  <Select
                    value={selectedRecipient?._id || ""}
                    onChange={handleRecipientChange}
                    label="Recipient"
                    fullWidth
                    sx={{
                      borderRadius: "12px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                      },
                    }}
                  >
                    {recipientData.length > 0 &&
                      recipientData.map((recipient) => (
                        <MenuItem
                          value={recipient.recipientID}
                          key={recipient.rowId}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Avatar
                              sx={{
                                width: 40,
                                height: 40,
                                bgcolor: "rgba(109, 30, 212, 0.3)",
                              }}
                            />
                            <Grid
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 2,
                              }}
                            >
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  color: theme.palette.text.primary,
                                }}
                              >
                                {recipient.name}
                              </label>
                            </Grid>
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {selectionError && (
                  <FormHelperText
                    sx={{
                      color: theme.palette.error.main,
                      fontWeight: 520,
                    }}
                  >
                    {selectionError}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} mt={3} px={1}>
                {selectedRecipient?._id && (
                  <Grid
                    sx={{
                      bgcolor: theme.palette.info.light,
                      borderRadius: "10px",
                      boxShadow: theme.palette.action.disabled,
                      marginTop: "10px",
                      padding: "10px",
                    }}
                  >
                    <Grid
                      xl={12}
                      lg={12}
                      sm={10}
                      md={10}
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        sx={{
                          gap: 2,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          bgcolor: theme.palette.info.light,
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                            bgcolor: "rgba(109, 30, 212, 0.3)",
                            marginLeft: "20px",
                          }}
                        />
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <Grid>
                            <label
                              style={{
                                fontSize: "16px",
                                fontWeight: 700,
                                lineHeight: "16px",
                                color: theme.palette.text.primary,
                              }}
                            >
                              {selectedRecipient?.name}
                            </label>
                          </Grid>
                          <Grid>
                            <label
                              style={{
                                fontSize: "12px",
                                color: theme.palette.info.main,
                              }}
                            >
                              {maskNumber(selectedRecipient?.payment)}
                            </label>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        onClick={() => navigate("/recipients")}
                      >
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: theme.palette.primary.main,
                            marginLeft: "20px",
                            cursor: "pointer",
                          }}
                        >
                          Choose Another
                        </label>
                      </Grid> */}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                xs={12}
                my={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      color="primary"
                      size="small" // Adjusting the checkbox size
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18, // Customizing checkbox icon size
                        },
                      }}
                    />
                  }
                  label="I accept the terms and conditions"
                />
              </Grid>
            </>
          )}
          <Box sx={{ marginTop: 3, textAlign: "center" }}>
            {recipientData.length > 0 ? (
              <Button
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "20px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                variant="contained"
                disabled={transactionLoad || !isChecked}
                onClick={() =>
                  kycStatus === "true"
                    ? makeTransferInterac()
                    : navigate("/kyc")
                }
              >
                Transfer Now
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "20px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                variant="contained"
                onClick={() => navigate("/recipients")}
              >
                Add Recipient
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
      <TermsModal show={openModal} onHide={() => setOpenModal(false)} />
    </Grid>
  );
};

export default Conversion;

// {
//   /* <Grid
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                   onClick={() => navigate("/recipients")}
//                 >
//                   <label
//                     style={{
//                       fontSize: "14px",
//                       fontWeight: 400,
//                       color: theme.palette.primary.main,
//                       marginLeft: "20px",
//                       cursor: "pointer",
//                     }}
//                   >
//                     Choose Another
//                   </label>
//                 </Grid> */
// }
