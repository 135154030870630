import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  useTheme as T,
  Box,
  Typography,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "../assets/themeContext";
import { CancelRounded, CheckCircle, Error, HourglassEmpty } from "@mui/icons-material";

interface Transaction {
  rowId: any;
  name: string;
  usdAmount: number;
  inrAmount: number;
  status: string;
  inrTransactionNumber: string | null;
  usdTransactionNumber: string | null;
  method: string;
  notes: string;
  date: string;
}

interface TransactionModalProps {
  show: boolean;
  onHide: () => void;
  transactionDetails: Transaction | null;
}

const TransactionModal: React.FC<TransactionModalProps> = ({
  show,
  onHide,
  transactionDetails,
}) => {
  const { theme } = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getStatusStyles = (status: string) => {
    switch (status.toLowerCase()) {
      case "pending":
        return { 
          icon: <Error sx={{ color: "#FFA500", fontSize: "40px" }} />, 
          color: "#FFA500" 
        };
      case "error":
        return { 
          icon: <CancelRounded sx={{ color: "red", fontSize: "40px" }} />, 
          color: "#FF0000" 
        };
      case "completed":
        return { 
          icon: <CheckCircle sx={{ color: "#4CBB17", fontSize: "40px" }} />, 
          color: "#4CBB17" 
        };
      default:
        return { 
          icon: <Error sx={{ color: theme.palette.text.primary, fontSize: "40px" }} />, 
          color: theme.palette.text.primary 
        };
    }
  };
  const statusStyles = getStatusStyles(transactionDetails?.status || "");

  return (
    <Dialog
      open={show}
      onClose={onHide}
      fullWidth
      maxWidth="sm"
      fullScreen={isSmallScreen}
      PaperProps={{ 
        sx: { 
          borderRadius: isSmallScreen ? "0px" : "20px", // No border radius when fullscreen
        } 
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onHide}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon style={{ color: theme.palette.text.primary }} />
      </IconButton>
      <DialogContent sx={{ bgcolor: theme.palette.primary.dark }}>
        <Box sx={{ width: isSmallScreen ? "100%" : "auto", overflowX: "auto" }}>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          padding={"10px"}
          display={"flex"}
          gap={"10px"}
          flexDirection={"column"}
        >
          <Box display="flex" flexDirection="column" alignItems="center" mb={1}>
          <Box
            sx={{
                width: 80,
                height: 80,
                borderRadius: "50%",
                backgroundColor: `${statusStyles.color}40`,
                display: "flex",
                justifyContent: "center",
                border: "none",
                alignItems: "center",
                "& svg": {    // Ensures icon alignment
                  width: "50px",
                  height: "50px",
                  color: statusStyles.color,
                }
              }}
            >
              {statusStyles.icon}
            </Box>
            <Typography
              variant="h5"
              fontWeight="510"
              mt={1}
              style={{
                color: theme.palette.text.primary,
                
              }}
            >
              Transaction {transactionDetails?.status}
            </Typography>
            <Typography variant="h5" fontWeight="bold" mt={1} style={{ color: theme.palette.text.primary,}} >
              INR {transactionDetails?.inrAmount}
            </Typography>
          </Box>
          <Divider sx={{ my: 2 ,borderColor: theme.palette.text.primary }} />
          <Box display="flex" justifyContent="space-between" mb={0.5}  fontSize={"1.5rem"}
              style={{
                color: theme.palette.text.primary,
              }}
          >
            <Typography variant="body1" style={{fontSize: isSmallScreen ? 15 : 18, fontWeight: 500}}>
              INR Transaction No.
            </Typography>
            <Typography variant="body1" style={{fontSize: isSmallScreen ? 13 : 17}}>
              {transactionDetails?.inrTransactionNumber || "N/A"}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" mb={0.5}  fontSize={"1.5rem"}
              style={{
                color: theme.palette.text.primary,
              }}
          >
            <Typography variant="body1" style={{fontSize: isSmallScreen ? 15 : 18, fontWeight: 500}}>
              USD Transaction No.
            </Typography>
            <Typography variant="body1" style={{fontSize: isSmallScreen ? 13 : 17}}>
              {transactionDetails?.usdTransactionNumber || "N/A"}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" mb={0.5} 
              style={{
                color: theme.palette.text.primary,
              }}
          >
            <Typography variant="body1" style={{fontSize: isSmallScreen ? 15 : 18, fontWeight: 500}}>
              Payment Date
            </Typography>
            <Typography style={{fontSize: isSmallScreen ? 13 : 17}}>
              {transactionDetails?.date}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" mb={0.5} 
              style={{
                color: theme.palette.text.primary,
              }}
          >
            <Typography variant="body1" style={{fontSize: isSmallScreen ? 15 : 18, fontWeight: 500}}>
              Payment Method
            </Typography>
            <Typography variant="body1" style={{fontSize: isSmallScreen ? 13 : 17}}>
              {transactionDetails?.method}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" mb={0.5} 
              style={{
                color: theme.palette.text.primary,
              }}
          >
            <Typography variant="body1" style={{fontSize: isSmallScreen ? 15 : 18, fontWeight: 500}}>
              Recipient Name
            </Typography>
            <Typography variant="body1" style={{fontSize: isSmallScreen ? 13 : 17}}>
              {transactionDetails?.name}
            </Typography>
          </Box>

          <Divider sx={{ my: 2 , borderColor: theme.palette.text.primary  }} />

          <Box display="flex" justifyContent="space-between" mb={0.5} 
              style={{
                color: theme.palette.text.primary,
                fontSize: "1rem",
              }}
          >
            <Typography variant="h6" fontWeight="550">
              Amount (CAD)
            </Typography>
            <Typography variant="h6" fontWeight="550">
              {transactionDetails?.usdAmount}
            </Typography>
          </Box>
        </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionModal;
